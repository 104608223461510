import { uploadVideoMapAsync } from "@vatsim-vnas/js-libs/api/data";
import { StarsMapBrightnessCategory, VideoMap as VideoMapModel } from "@vatsim-vnas/js-libs/models/video-maps";
import { dateToZuluString, getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CommaListInput,
  DownloadIconButton,
  Input,
  SaveButtonBar,
  SelectInput,
  Switch,
  UploadFileButton,
  UploadFileButtonHandle,
} from "src/components/ui";
import {
  artccSelector,
  saveArtcc,
  saveVideoMap,
  setUnsavedData,
  useAppDispatch,
  useAppSelector,
  vnasConfigurationSelector,
} from "src/redux";
import { FileUpload } from "src/utils";

function VideoMap() {
  const id = useParams().id!;
  const artcc = useAppSelector(artccSelector);
  const vnasConfiguration = useAppSelector(vnasConfigurationSelector);
  const dispatch = useAppDispatch();

  const videoMap = useMemo(() => artcc.videoMaps.find((v) => v.id.toString() === id)!, []);
  const [uploading, setUploading] = useState(false);
  const geojsonInputRef = useRef<UploadFileButtonHandle>(undefined!);

  const formik = useFormik({
    initialValues: videoMap,
    enableReinitialize: true,
    validationSchema: VideoMapModel.createSchema(artcc),
    onSubmit: (values) => {
      dispatch(saveVideoMap(values));
      dispatch(saveArtcc("Successfully saved changes"));
      formik.resetForm({ values });
    },
  });

  const handleUpload = async (file: FileUpload) => {
    formik.setFieldTouched("file", true, false);
    if (!(file instanceof File)) {
      return;
    }

    if (file.size === 0) {
      formik.setFieldError("file", "Invalid file: file cannot be empty.");
      return;
    }

    formik.setFieldError("file", undefined);
    setUploading(true);
    const res = await uploadVideoMapAsync(artcc.id, videoMap.id, file);

    if (res.ok) {
      videoMap.lastUpdatedAt = new Date();
      videoMap.sourceFileName = file.name;
      dispatch(saveVideoMap(videoMap));
      dispatch(saveArtcc());
      toast.success("Successfully uploaded video map");
    } else {
      toast.error(`Failed to upload video map: ${res.statusText}`);
    }
    setUploading(false);

    geojsonInputRef.current.clear();
  };

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (!videoMap) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">{videoMap.name}</h1>
      <section className="content">
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Card.Header>
                <Card.Title>General Settings</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    <Input label="Name" name="name" placeholder="A90-003S (MVA)" formik={formik} allowLowercase />
                  </Col>
                  <Col md className="mb-3">
                    <CommaListInput
                      name="tags"
                      label="Tags"
                      placeholder="STARS, EOVM, ..."
                      formik={formik}
                      sort={false}
                      allowSpaces
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Switch formik={formik} name="tdmOnly" label="TDM Only" />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={3} className="mb-2">
                    <UploadFileButton
                      label="Upload"
                      accept=".geojson"
                      processing={uploading}
                      ref={geojsonInputRef}
                      fileName={`Last updated: ${dateToZuluString(videoMap.lastUpdatedAt)}`}
                      onSelect={handleUpload}
                    />
                  </Col>
                  <Col lg={3}>
                    <DownloadIconButton
                      url={`${vnasConfiguration.dataApiBaseUrl}/artccs/${artcc.id}/video-maps/${videoMap.id}`}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>STARS Settings</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={3} className="mb-3">
                    <Input
                      label="Map ID"
                      name="starsId"
                      formik={formik}
                      placeholder="100"
                      useUndefinedForEmpty
                      number
                      disabled={!videoMap.starsIdIsEditable(artcc)}
                    />
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Input label="Short Name" name="shortName" formik={formik} placeholder="MVA" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <SelectInput
                      formik={formik}
                      label="Brightness Category"
                      name="starsBrightnessCategory"
                      options={getEnumOptions(StarsMapBrightnessCategory)}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Switch name="starsAlwaysVisible" label="Always Visible" formik={formik} />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <SaveButtonBar backText="Back to Video Maps" formik={formik} />
          </Form>
        </Container>
      </section>
    </>
  );
}

export default VideoMap;
