import { FormikProps, getIn } from "formik";
import React, { ReactNode, useId } from "react";
import { Form } from "react-bootstrap";

export interface SelectInputProps<F> {
  formik: FormikProps<F>;
  name: string;
  label?: string;
  options: ReactNode;
  clearOption?: string;
  valueProcessor?: (value: string) => string | undefined;
  disabled?: boolean;
}

function SelectInput<F>({
  formik,
  label = undefined,
  name,
  options,
  clearOption = undefined,
  valueProcessor = undefined,
  disabled = false,
}: Readonly<SelectInputProps<F>>) {
  const id = useId();

  const handleOnChange = (value: string) => {
    let finalValue: string | undefined = value;

    if (valueProcessor) {
      finalValue = valueProcessor(finalValue);
    }

    formik.setFieldValue(name, clearOption && finalValue === clearOption ? undefined : finalValue);
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <Form.Control
        as="select"
        className="form-control"
        data-default={
          (!clearOption && getIn(formik.values, name) === undefined) || getIn(formik.values, name) === undefined
        }
        disabled={disabled}
        id={id}
        isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
        name={name}
        onBlur={formik.handleBlur}
        value={getIn(formik.values, name) ?? "-1"}
        onChange={(e) => handleOnChange(e.target.value)}
      >
        {clearOption ? (
          <option value={clearOption}>{clearOption}</option>
        ) : (
          <option disabled value="-1">
            - Select {label} -
          </option>
        )}
        {options}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default SelectInput;
