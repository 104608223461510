import { uploadAsdexRunwaysAsync } from "@vatsim-vnas/js-libs/api/data";
import {
  AsdexConfiguration as AsdexConfigurationModel,
  AsdexFixRule,
  AsdexPosition,
  AsdexRunwayConfiguration,
  Facility,
} from "@vatsim-vnas/js-libs/models/facilities";
import { CanDeleteSpec } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import {
  CommaListInput,
  DeleteIconButton,
  DownloadIconButton,
  EditIconButton,
  ErrorTableCell,
  Input,
  OrderButtonPair,
  SelectInput,
  Switch,
  Table,
  TableHeader,
  TableNoRows,
  UploadFileButton,
  UploadFileButtonHandle,
  VideoMapInput,
} from "src/components/ui";
import { DeleteModal, DeleteModalSpec, ModalSpec, RunwayConfigurationModal } from "src/components/ui/modal";
import { artccSelector, useAppSelector, vnasConfigurationSelector } from "src/redux";
import * as S from "src/styles/ui";
import { FileUpload, addToFormikArray, deleteFromFormikArray, processResponse } from "src/utils";

const DEFAULT_POSITION_DELETE_SPEC = new CanDeleteSpec(["Position is Default Position"]);

interface AsdexConfigurationProps {
  formik: FormikProps<Facility>;
}

function AsdexConfiguration({ formik }: Readonly<AsdexConfigurationProps>) {
  const artccId = useAppSelector(artccSelector).id;
  const { dataApiBaseUrl } = useAppSelector(vnasConfigurationSelector);

  const [uploadingSafetyLogicConfiguration, setUploadingSafetyLogicConfiguration] = useState(false);
  const [runwayConfigurationModalSpec, setRunwayConfigurationModalSpec] = useState<ModalSpec>({ show: false });
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec>({ show: false });

  const safetyLogicConfigurationInputRef = useRef<UploadFileButtonHandle>(undefined!);
  const runwayConfigurationsTableRef = useRef<HTMLDivElement>(undefined!);
  const positionsTableRef = useRef<HTMLDivElement>(undefined!);
  const fixRulesTableRef = useRef<HTMLDivElement>(undefined!);

  const facilityId = formik.values.id;
  const { asdexConfiguration } = formik.values;

  const handleSelectSafetyLogicConfiguration = async (file: FileUpload) => {
    if (file instanceof File && file.size > 0) {
      setUploadingSafetyLogicConfiguration(true);
      const res = await uploadAsdexRunwaysAsync(artccId, facilityId, file);
      processResponse(
        res,
        `Failed to upload safety logic configuration: ${res.statusText}`,
        "Successfully uploaded safety logic configuration",
      );
      setUploadingSafetyLogicConfiguration(false);
      safetyLogicConfigurationInputRef.current.clear();
    }
  };

  const handleAddRunwayConfiguration = async () => {
    const configuration = new AsdexRunwayConfiguration();
    const index = addToFormikArray(
      formik,
      "asdexConfiguration.runwayConfigurations",
      configuration,
      runwayConfigurationsTableRef,
    );
    setRunwayConfigurationModalSpec({ show: true, index });
  };

  const handleDeletePosition = (position: AsdexPosition) => {
    if (asdexConfiguration!.defaultPositionId === position.id) {
      setDeleteModalSpec({ itemName: position.name, show: true });
    } else {
      deleteFromFormikArray(formik, "asdexConfiguration.positions", position);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>ASDE-X Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Switch
              label="Enable ASDE-X"
              checkedValue={() => new AsdexConfigurationModel()}
              formik={formik}
              name="asdexConfiguration"
            />
          </Col>
        </Row>
        {asdexConfiguration && (
          <>
            <Row className="mt-3">
              <Col md className="mb-3">
                <Input
                  formik={formik}
                  label="Tower Location Latitude"
                  name="asdexConfiguration.towerLocation.lat"
                  number
                  placeholder="42.3355"
                />
              </Col>
              <Col md className="mb-3">
                <Input
                  formik={formik}
                  label="Tower Location Longitude"
                  name="asdexConfiguration.towerLocation.lon"
                  number
                  placeholder="-71.1685"
                />
              </Col>
            </Row>
            <Row>
              <Col lg className="mb-3">
                <Input
                  label="Default Rotation"
                  name="asdexConfiguration.defaultRotation"
                  formik={formik}
                  placeholder="0"
                  number
                />
              </Col>
              <Col lg className="mb-3">
                <Input
                  label="Default Zoom Range"
                  name="asdexConfiguration.defaultZoomRange"
                  formik={formik}
                  placeholder="100"
                  number
                />
              </Col>
              <Col lg className="mb-3">
                <Input
                  label="Target Visibility Range"
                  name="asdexConfiguration.targetVisibilityRange"
                  formik={formik}
                  placeholder="15"
                  number
                />
              </Col>
              <Col lg className="mb-3">
                <Input
                  label="Target Visibility Ceiling (MSL)"
                  name="asdexConfiguration.targetVisibilityCeiling"
                  formik={formik}
                  placeholder="1500"
                  number
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <VideoMapInput formik={formik} name="asdexConfiguration.videoMapId" label="Video Map" />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <S.Label>Safety Logic Configuration</S.Label>
              </Col>
            </Row>
            <Row>
              <Col lg={2} className="mb-2">
                <UploadFileButton
                  label="Upload"
                  accept=".geojson"
                  processing={uploadingSafetyLogicConfiguration}
                  ref={safetyLogicConfigurationInputRef}
                  showFileName={false}
                  onSelect={handleSelectSafetyLogicConfiguration}
                />
              </Col>
              <Col lg={2} className="mb-3">
                <DownloadIconButton url={`${dataApiBaseUrl}/artccs/${artccId}/asdex-runways/${facilityId}`} />
              </Col>
            </Row>
            <Row>
              <TableHeader
                label="Runway Configurations"
                addButtonLabel="Add Runway Configuration"
                onAdd={handleAddRunwayConfiguration}
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table ref={runwayConfigurationsTableRef} maxHeight={500}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asdexConfiguration.runwayConfigurations.map((runwayConfiguration, i) => (
                      <tr key={runwayConfiguration.id}>
                        <td>
                          <ErrorTableCell
                            displayValue={runwayConfiguration.name}
                            formik={formik}
                            name={`asdexConfiguration.runwayConfigurations[${i}]`}
                            errorMessage="Invalid Runway Configuration"
                          />
                        </td>
                        <td>
                          <ButtonGroup>
                            <EditIconButton onClick={() => setRunwayConfigurationModalSpec({ show: true, index: i })} />
                            <DeleteIconButton
                              onClick={() =>
                                deleteFromFormikArray(
                                  formik,
                                  "asdexConfiguration.runwayConfigurations",
                                  runwayConfiguration,
                                )
                              }
                            />
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                    <TableNoRows
                      rows={asdexConfiguration.runwayConfigurations}
                      text="No Runway Configurations defined"
                    />
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <S.Label>Default Position</S.Label>
                <SelectInput
                  formik={formik}
                  name="asdexConfiguration.defaultPositionId"
                  options={asdexConfiguration.positions.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <TableHeader
                label="Positions"
                addButtonLabel="Add Position"
                onAdd={() =>
                  addToFormikArray(formik, "asdexConfiguration.positions", new AsdexPosition(), positionsTableRef)
                }
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table ref={positionsTableRef} maxHeight={500}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Runways</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asdexConfiguration.positions.map((position, i) => (
                      <tr key={position.id}>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`asdexConfiguration.positions[${i}].name`}
                            placeholder="LC1"
                          />
                        </td>
                        <td>
                          <CommaListInput
                            tableCell
                            formik={formik}
                            name={`asdexConfiguration.positions[${i}].runwayIds`}
                            placeholder="4R-22L, 9-27"
                          />
                        </td>
                        <td>
                          <DeleteIconButton onClick={() => handleDeletePosition(position)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <S.Label>Fix Rules</S.Label>
                <Switch
                  label="Use destination's FAA ID as fallback fix ID"
                  name="asdexConfiguration.useDestinationIdAsFix"
                  formik={formik}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <TableHeader
                addButtonLabel="Add Fix Rule"
                onAdd={() =>
                  addToFormikArray(formik, "asdexConfiguration.fixRules", new AsdexFixRule(), fixRulesTableRef)
                }
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table ref={fixRulesTableRef} maxHeight={500}>
                  <thead>
                    <tr>
                      <th>Search Pattern</th>
                      <th>Fix ID</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asdexConfiguration.fixRules.map((fixRule, i) => (
                      <tr key={fixRule.id}>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`asdexConfiguration.fixRules[${i}].searchPattern`}
                            placeholder="ORD# EBAKE"
                          />
                        </td>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`asdexConfiguration.fixRules[${i}].fixId`}
                            placeholder="EBA"
                          />
                        </td>
                        <td>
                          <ButtonGroup>
                            <OrderButtonPair formik={formik} index={i} name="asdexConfiguration.fixRules" />
                            <DeleteIconButton
                              onClick={() => deleteFromFormikArray(formik, "asdexConfiguration.fixRules", fixRule)}
                            />
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                    <TableNoRows rows={asdexConfiguration.fixRules} text="No Fix Rules defined" />
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
      <Card.Footer />
      <RunwayConfigurationModal
        formik={formik}
        onClose={() => setRunwayConfigurationModalSpec((p) => ({ ...p, show: false }))}
        index={runwayConfigurationModalSpec.index}
        show={runwayConfigurationModalSpec.show}
      />
      <DeleteModal
        canDeleteSpec={DEFAULT_POSITION_DELETE_SPEC}
        itemName={deleteModalSpec.itemName}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
        show={deleteModalSpec.show}
      />
    </Card>
  );
}

export default AsdexConfiguration;
