import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { findOrError } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { artccSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";

interface MapGroupModalProps {
  formik: FormikProps<Facility>;
  index: number | undefined;
  onClose: () => void;
  show: boolean;
}

export interface MapGroupModalSpec {
  show: boolean;
  index?: number;
}

function MapGroupModal({ formik, index, onClose, show }: Readonly<MapGroupModalProps>) {
  const artcc = useAppSelector(artccSelector);
  const starsConfiguration = formik.values.starsConfiguration!;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const videoMaps = useMemo(
    () =>
      artcc.videoMaps
        .filter((v) => starsConfiguration.videoMapIds.includes(v.id))
        .sort((a, b) => a.starsId! - b.starsId!),
    [starsConfiguration.videoMapIds],
  );

  if (index === undefined) {
    return undefined;
  }

  const mapGroup = starsConfiguration.mapGroups[index];

  if (!mapGroup) {
    return undefined;
  }

  const getVideoMapShortName = (mapIdIndex: number) => {
    const starsId = mapGroup.mapIds[mapIdIndex]!;
    if (starsId !== undefined) {
      return findOrError(videoMaps, (v) => v.starsId === starsId, `STARS map ID ${starsId} not found`).shortName!;
    }
    return "";
  };

  const getDefaultSelected = (mapIdIndex: number) => {
    const starsId = mapGroup.mapIds[mapIdIndex]!;
    if (starsId !== undefined) {
      return [findOrError(videoMaps, (v) => v.starsId === starsId, `STARS map ID ${starsId} not found`)];
    }
    return undefined;
  };

  const generateButtonRows = (start: number, end: number) => {
    return Array.from({ length: Math.floor((end - start + 2) / 2) }, (_, i) => start + i * 2).map((i) => (
      <td key={i} className="p-0">
        <S.DcbButton $isSelected={i === selectedIndex} onClick={() => setSelectedIndex(i)}>
          {getVideoMapShortName(i)}
        </S.DcbButton>
      </td>
    ));
  };

  const handleChange = (videoMap: VideoMap) => {
    formik.setFieldValue(`starsConfiguration.mapGroups[${index}].mapIds[${selectedIndex}]`, videoMap?.starsId);
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="xl">
      <Modal.Header className="dark-mode">
        <Modal.Title>Map Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>DCB Main Menu</Form.Label>
              <S.LabelTabel className="table table-bordered w-auto">
                <tbody>
                  <tr>{generateButtonRows(0, 4)}</tr>
                  <tr>{generateButtonRows(1, 5)}</tr>
                </tbody>
              </S.LabelTabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>DCB Submenu</Form.Label>
              <div className="overflow-auto">
                <S.LabelTabel className="table table-bordered">
                  <tbody>
                    <tr>{generateButtonRows(6, 36)}</tr>
                    <tr>{generateButtonRows(7, 37)}</tr>
                  </tbody>
                </S.LabelTabel>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Select Video Map</Form.Label>
              <Typeahead
                key={selectedIndex}
                defaultSelected={getDefaultSelected(selectedIndex)}
                clearButton
                flip
                id="video-maps"
                labelKey={(option) => `(${(option as VideoMap).starsId}) ${(option as VideoMap).name}`}
                onChange={(v) => handleChange(v[0] as VideoMap)}
                options={videoMaps}
                paginate
                placeholder="Select video map..."
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={() => onClose()}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default MapGroupModal;
