import { AsdexHoldShortRunwayPair, Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { CommaListInput, DeleteIconButton, Input, Table, TableHeader } from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface RunwayConfigurationModalProps {
  index: number | undefined;
  show: boolean;
  onClose: () => void;
  formik: FormikProps<Facility>;
}

function RunwayConfigurationModal({ index, show, onClose, formik }: Readonly<RunwayConfigurationModalProps>) {
  if (index === undefined) {
    return undefined;
  }

  const runwayConfiguration = formik.values.asdexConfiguration!.runwayConfigurations[index];

  if (!runwayConfiguration) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={onClose} backdrop="static" size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Runway Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <Input
              name={`asdexConfiguration.runwayConfigurations[${index}].name`}
              formik={formik}
              placeholder="NORTH"
              label="Name"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CommaListInput
              name={`asdexConfiguration.runwayConfigurations[${index}].arrivalRunwayIds`}
              formik={formik}
              placeholder="4R, 9..."
              label="Arrival Runways"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CommaListInput
              name={`asdexConfiguration.runwayConfigurations[${index}].departureRunwayIds`}
              formik={formik}
              placeholder="4R, 9..."
              label="Departure Runways"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <TableHeader
            label="LAHSO Definitions"
            addButtonLabel="Add Definition"
            onAdd={() =>
              addToFormikArray(
                formik,
                `asdexConfiguration.runwayConfigurations[${index}].holdShortRunwayPairs`,
                new AsdexHoldShortRunwayPair(),
              )
            }
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Landing Runway</th>
                  <th>Hold Short Runway/Taxiway</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {runwayConfiguration.holdShortRunwayPairs.map((p, i) => (
                  <tr key={p.id}>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`asdexConfiguration.runwayConfigurations[${index}].holdShortRunwayPairs[${i}].arrivalRunwayId`}
                        placeholder="22L"
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`asdexConfiguration.runwayConfigurations[${index}].holdShortRunwayPairs[${i}].holdShortId`}
                        placeholder="27"
                      />
                    </td>
                    <td>
                      <DeleteIconButton
                        onClick={() =>
                          deleteFromFormikArray(
                            formik,
                            `asdexConfiguration.runwayConfigurations[${index}].holdShortRunwayPairs`,
                            p,
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RunwayConfigurationModal;
