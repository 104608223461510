import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronUp,
  faFileDownload,
  faGear,
  faMagnifyingGlass,
  faPencil,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as S from "src/styles/ui";

interface IconButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: IconProp;
  onClick?: React.MouseEventHandler;
  spin?: boolean;
  submit?: boolean;
  text?: string;
  type: "primary" | "secondary" | "success" | "danger" | "discord";
  width?: number;
}

interface AddIconButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler;
  text: string;
}

interface EditIconButtonProps {
  disabled?: boolean;
  onClick: React.MouseEventHandler;
}

interface DeleteIconButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler;
  text?: string;
}

interface OrderIconButtonProps {
  disabled: boolean;
  onClick: React.MouseEventHandler;
  up?: boolean;
}

interface DownloadIconButtonProps {
  text?: string;
  url: string;
}

interface ProcessButtonProps {
  className?: string;
  disabled: boolean;
  onClick: React.MouseEventHandler;
  processing: boolean;
  text: string;
}

function IconButton({
  className = "",
  disabled = false,
  icon = undefined,
  onClick = undefined,
  spin = false,
  submit = false,
  text = undefined,
  type,
  width = undefined,
}: Readonly<IconButtonProps>) {
  const getIconClassName = () => {
    let iconClassName = spin ? "fa-spin" : "";
    if (text) {
      iconClassName += " mr-2";
    }
    return iconClassName.trim();
  };
  return (
    <S.IconButton
      disabled={disabled}
      type={submit ? "submit" : "button"}
      className={`btn btn-${type} ${className}`.trim()}
      onClick={onClick}
      $width={width}
    >
      {icon && <FontAwesomeIcon icon={icon} className={getIconClassName()} />}
      {text}
    </S.IconButton>
  );
}

export function AddIconButton({ disabled = false, onClick, text }: Readonly<AddIconButtonProps>) {
  return <IconButton disabled={disabled} icon={faPlus} type="success" onClick={onClick} text={text} />;
}

export function EditIconButton({ disabled = false, onClick }: Readonly<EditIconButtonProps>) {
  return <IconButton icon={faPencil} type="primary" onClick={onClick} disabled={disabled} />;
}

export function DeleteIconButton({
  className = undefined,
  disabled = false,
  onClick,
  text = undefined,
}: Readonly<DeleteIconButtonProps>) {
  return (
    <IconButton
      icon={faTrashAlt}
      type="danger"
      onClick={onClick}
      disabled={disabled}
      className={className}
      text={text}
    />
  );
}

export function OrderIconButton({ disabled, onClick, up = false }: Readonly<OrderIconButtonProps>) {
  return <IconButton icon={up ? faChevronUp : faChevronDown} onClick={onClick} type="secondary" disabled={disabled} />;
}

export function DownloadIconButton({ url, text = "Download" }: Readonly<DownloadIconButtonProps>) {
  return (
    <IconButton
      icon={faFileDownload}
      text={text}
      type="secondary"
      className="btn-block"
      onClick={() => window.open(url)}
    />
  );
}

export function ProcessButton({ className = "", disabled, onClick, processing, text }: Readonly<ProcessButtonProps>) {
  return (
    <IconButton
      disabled={disabled}
      icon={processing ? faGear : undefined}
      text={processing ? "" : text}
      spin={processing}
      type="success"
      className={className}
      onClick={onClick}
      width={150}
    />
  );
}

interface ViewButtonProps {
  onClick: React.MouseEventHandler;
}

export function ViewButton({ onClick }: Readonly<ViewButtonProps>) {
  return <IconButton type="primary" onClick={onClick} icon={faMagnifyingGlass} />;
}

export default IconButton;
