import { UserRole } from "@vatsim-vnas/js-libs/models/authorization";
import { ArtccRole, UserInfo, artccRoleToString } from "@vatsim-vnas/js-libs/models/data-api";

import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input, SelectInput } from "src/components/ui";
import { useAppSelector, userSelector } from "src/redux";

interface UserModalProps {
  show: boolean;
  userRole?: UserRole;
  isAdding: boolean;
  onClose: (userRole?: UserRole) => void;
}

export interface UserModalSpec {
  show: boolean;
  userRole?: UserRole;
  isAdding?: boolean;
}

const getArtccIds = (user: UserInfo) => {
  if (user.isAdmin) {
    return user.artccSummaries.map((a) => a.id).sort();
  }
  return [
    ...user.artccRoles
      .filter((r) => r.role === ArtccRole.Administrator || r.role === ArtccRole.TrainingAdministrator)
      .map((r) => r.artccId),
  ].sort();
};

const roleOptionIsVisible = (formik: FormikProps<UserRole>, role: ArtccRole, user: UserInfo) => {
  if (user.isAdmin) {
    return true;
  }

  if (!formik.values) {
    return false;
  }

  if (role === ArtccRole.Administrator) {
    return false;
  }

  if (role === ArtccRole.FacilityEngineer) {
    return user.hasRole(ArtccRole.Administrator, formik.values.artccId);
  }

  return (
    user.hasRole(ArtccRole.TrainingAdministrator, formik.values.artccId) ||
    user.hasRole(ArtccRole.Administrator, formik.values.artccId)
  );
};

function UserRoleModal({ show, userRole = undefined, isAdding, onClose }: Readonly<UserModalProps>) {
  const user: UserInfo = useAppSelector(userSelector);
  const artccIds = useMemo(() => getArtccIds(user), [user]);

  const formik = useFormik({
    initialValues: userRole!,
    validationSchema: UserRole.createSchema(user.cid),
    onSubmit: onClose,
  });

  useEffect(() => formik.resetForm({ values: userRole }), [userRole]);

  if (!userRole) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>{`${isAdding ? "Add" : "Edit"} ${userRole.isAdmin ? "Administrator" : "User"}`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Row>
            <Col>
              <Input formik={formik} name="userId" label="CID" placeholder="1234567" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Input formik={formik} name="firstName" label="First Name" placeholder="John" allowLowercase />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Input formik={formik} name="lastName" label="Last Name" placeholder="Smith" allowLowercase />
            </Col>
          </Row>
          {!userRole.isAdmin && (
            <>
              <Row className="mt-3">
                <Col>
                  <SelectInput
                    formik={formik}
                    valueProcessor={(value) => {
                      formik.setFieldValue("role", undefined);
                      return value;
                    }}
                    name="artccId"
                    label="ARTCC"
                    options={artccIds.map((a) => (
                      <option key={a} value={a}>
                        {a}
                      </option>
                    ))}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <SelectInput
                    formik={formik}
                    name="role"
                    label="Role"
                    options={getEnumOptions(ArtccRole, artccRoleToString, (r) =>
                      roleOptionIsVisible(formik, r as ArtccRole, user),
                    )}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose(undefined)}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid}>
            Save
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UserRoleModal;
