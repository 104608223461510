import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AddIconButton } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";

interface TableHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  addButtonLabel?: string;
  addButtonDisabled?: boolean;
  children?: ReactNode;
  headerLabel?: string;
  label?: string;
  onAdd?: () => void;
  onSearch?: (value: string) => void;
  onSelectTags?: (value: string[]) => void;
  searchValue?: string;
  tagsValue?: string[];
}

function TableHeader({
  addButtonLabel = undefined,
  addButtonDisabled = false,
  children = undefined,
  headerLabel = undefined,
  label = undefined,
  onAdd = undefined,
  onSearch = undefined,
  onSelectTags = undefined,
  searchValue = undefined,
  tagsValue = undefined,
}: Readonly<TableHeaderProps>) {
  const artcc = useAppSelector(artccSelector);

  return (
    <>
      {label && (
        <Col className="align-self-end">
          <S.Label className="mb-0">{label}</S.Label>
        </Col>
      )}
      {headerLabel && (
        <Col className="align-self-end">
          <h5>{headerLabel}</h5>
        </Col>
      )}
      <Col md className="text-right">
        <Row>
          {!!children && <Col className="mb-2 mb-md-0 text-left d-flex align-items-center">{children}</Col>}
          {onSelectTags && (
            <Col md className="mb-2 mb-md-0">
              <Typeahead
                id="video-map-tags"
                options={artcc.getVideoMapTags()}
                defaultSelected={tagsValue}
                flip
                placeholder="Filter tags..."
                paginate
                multiple
                onChange={(t) => {
                  onSelectTags(t as string[]);
                }}
              />
            </Col>
          )}
          {onSearch && (
            <Col md className="mb-2 mb-md-0">
              <input
                className="form-control"
                value={searchValue}
                onChange={(e) => (onSearch ? onSearch(e.target.value) : undefined)}
                placeholder="Search..."
              />
            </Col>
          )}
          {onAdd && (
            <Col md="auto" className="ml-auto">
              <AddIconButton onClick={onAdd} text={addButtonLabel!} disabled={addButtonDisabled} />
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
}

export default TableHeader;
