import { getTrainingScenarioAsync } from "@vatsim-vnas/js-libs/api/data";
import { Scenario, ScenarioAircraft } from "@vatsim-vnas/js-libs/models/training";
import { deepCopy } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Table } from "src/components/ui";
import { trainingScenarioSummariesSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";
import { ulid } from "ulid";

interface TrainingScenarioImportModallProps {
  show: boolean;
  formik: FormikProps<Scenario>;
  onClose: () => void;
}

function TrainingScenarioImportModal({ show, formik, onClose }: Readonly<TrainingScenarioImportModallProps>) {
  const allTrainingScenarios = useAppSelector(trainingScenarioSummariesSelector);
  const trainingScenarios = useMemo(() => allTrainingScenarios.filter((s) => s.id !== formik.values.id), []);
  const [allAircraft, setAllAircraft] = useState<ScenarioAircraft[]>([]);
  const [selectedAircraft, setSelectedAircraft] = useState<ScenarioAircraft[]>([]);

  const handleSelectScenario = async (scenarioId: string) => {
    setSelectedAircraft([]);
    setAllAircraft([]);
    const res = await getTrainingScenarioAsync(scenarioId);
    if (res.ok) {
      setAllAircraft(res.data!.aircraft);
    } else {
      toast.error(`Failed to load scenario: ${res.statusText}`);
    }
  };

  useEffect(() => {
    if (show) {
      handleSelectScenario(trainingScenarios[0].id);
    } else {
      setSelectedAircraft([]);
    }
  }, [show]);

  const handleSelectAircraft = (aircraft: ScenarioAircraft, selected: boolean) => {
    const aircraftList = [...selectedAircraft];
    if (selected) {
      aircraftList.push(aircraft);
    } else {
      aircraftList.splice(aircraftList.indexOf(aircraft), 1);
    }
    setSelectedAircraft(aircraftList);
  };

  const handleImport = () => {
    const newAircraftList = [...formik.values.aircraft];
    selectedAircraft.forEach((a) => {
      const newAircraft = deepCopy(a);
      newAircraft.id = ulid();
      newAircraftList.push(newAircraft);
    });

    formik.setFieldValue("aircraft", newAircraftList);
    toast.success(`Aircraft imported successfully.`);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Import From Scenario</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Scenario</Form.Label>
              <Form.Control as="select" onChange={(e) => handleSelectScenario(e.target.value)}>
                {trainingScenarios.map((a) => (
                  <option key={a.id} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Aircraft</Form.Label>
              <S.ScrollContainer>
                {!allAircraft ? (
                  <i>No aircraft in selected scenario</i>
                ) : (
                  <Table maxHeight={500}>
                    <tbody>
                      {allAircraft.map((a) => (
                        <tr key={a.id}>
                          <td>
                            <Form.Check
                              label={`${a.aircraftId} (${a.aircraftType})`}
                              onChange={(e) => handleSelectAircraft(a, e.target.checked)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </S.ScrollContainer>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-secondary" type="button" onClick={onClose}>
          Close
        </button>
        <button className="btn btn-primary" type="button" onClick={handleImport} disabled={!selectedAircraft}>
          Import
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingScenarioImportModal;
