import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  deleteVideoMapAsync,
  getArtccSummaryAsync,
  loadArtccAsync,
  saveArtccAsync,
  uploadVideoMapAsync,
} from "@vatsim-vnas/js-libs/api/data";
import { Artcc, AutoAtcRule, Facility, Transceiver } from "@vatsim-vnas/js-libs/models/facilities";
import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { instanceToInstance } from "class-transformer";
import { toast } from "react-toastify";
import { processResponse } from "src/utils";
import { RootState } from "../store";

interface AddFacilityProps {
  facility: Facility;
  parentFacilityId: string;
}

interface UploadVideoMapProps {
  videoMapId: string;
  videoMap: File;
}

export const loadArtcc = createAsyncThunk("artcc/loadArtcc", async (artccId: string) => {
  const res = await loadArtccAsync(artccId);
  return processResponse(res, "Failed to load ARTCC");
});

export const saveArtcc = createAsyncThunk("artcc/saveArtcc", async (displayMessage: string | undefined, thunkAPI) => {
  const artcc = (thunkAPI.getState() as RootState).artcc.artcc!;
  const res = await saveArtccAsync(artcc, false);
  if (res.ok) {
    toast.success(displayMessage);
    const summaryRes = await getArtccSummaryAsync(artcc.id);
    if (summaryRes.ok) {
      return { succeeded: true, updatedAt: summaryRes.data!.lastUpdatedAt };
    }
    return { succeeded: false };
  }
  if (res.status === 409) {
    return { succeeded: false, confirmOverwrite: true };
  }
  if (displayMessage) {
    toast.error(`Failed to save changes: ${res.statusText}`);
  }
  return { succeeded: false };
});

export const forceSaveArtcc = createAsyncThunk("artcc/forceSaveArtcc", async (_, thunkAPI) => {
  const artcc = (thunkAPI.getState() as RootState).artcc.artcc!;
  const res = await saveArtccAsync(artcc, true);
  if (res.ok) {
    toast.success("Successfully force saved changes");
    const summaryRes = await getArtccSummaryAsync(artcc.id);
    return processResponse(summaryRes);
  }
  toast.error(`Failed to force save changes: ${res.statusText}`);
  return undefined;
});

export const uploadVideoMap = createAsyncThunk("artcc/uploadVideoMap", async (data: UploadVideoMapProps, thunkAPI) => {
  const artcc = (thunkAPI.getState() as RootState).artcc.artcc!;
  const res = await uploadVideoMapAsync(artcc.id, data.videoMapId, data.videoMap);
  return processResponse(res, "Failed to upload video map");
});

export const deleteVideoMaps = createAsyncThunk("artcc/deleteVideoMaps", async (videoMapIds: string[], thunkAPI) => {
  const artcc = (thunkAPI.getState() as RootState).artcc.artcc!;
  let ok = true;
  const statusText: string[] = [];
  const promises = videoMapIds.map(async (id) => {
    const res = await deleteVideoMapAsync(artcc.id, id);
    if (!res.ok && res.status !== 404) {
      statusText.push(res.statusText);
      ok = false;
    }
  });

  await Promise.all(promises);
  if (ok) {
    return videoMapIds;
  }
  toast.error(`Failed to delete video map: ${statusText.join(", ")}`);
  return undefined;
});

interface ArtccState {
  artcc?: Artcc;
  loaded: boolean;
  confirmOverwrite: boolean;
}

const initialState: ArtccState = {
  loaded: false,
  confirmOverwrite: false,
};

const artccSlice = createSlice({
  name: "artcc",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadArtcc.fulfilled, (state, action) => {
      if (action.payload) {
        state.loaded = true;
        state.artcc = action.payload;
      }
    });
    builder.addCase(loadArtcc.rejected, (_, action) => {
      toast.error(`Failed to load ARTCC: ${action.error.message}`);
    });
    builder.addCase(saveArtcc.fulfilled, (state, action) => {
      if (action.payload.succeeded) {
        state.artcc!.lastUpdatedAt = action.payload.updatedAt!;
      } else if (action.payload.confirmOverwrite) {
        state.confirmOverwrite = true;
      }
    });
    builder.addCase(forceSaveArtcc.fulfilled, (state, action) => {
      state.confirmOverwrite = false;
      if (action.payload) {
        state.artcc!.lastUpdatedAt = action.payload.lastUpdatedAt;
      }
    });
    builder.addCase(forceSaveArtcc.rejected, (state, action) => {
      state.confirmOverwrite = false;
      toast.error(`Failed to force save ARTCC: ${action.error.message}`);
    });
    builder.addCase(deleteVideoMaps.fulfilled, (state, action) => {
      if (action.payload) {
        const newArtcc = instanceToInstance(state.artcc!);
        newArtcc.videoMaps = newArtcc.videoMaps.filter((v) => !action.payload!.includes(v.id));
        state.artcc = newArtcc;
      }
    });
    builder.addCase(deleteVideoMaps.rejected, (_, action) => {
      toast.error(`Failed to delete video map: ${action.error.message}`);
    });
  },
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    resetConfirmOverwrite: (state) => {
      state.confirmOverwrite = false;
    },
    addFacility: (state, action: PayloadAction<AddFacilityProps>) => {
      const parentFacility = state.artcc!.getFacility(action.payload.parentFacilityId);
      parentFacility.childFacilities.push(action.payload.facility);
    },
    deleteFacility: (state, action: PayloadAction<Facility>) => {
      const newArtcc = instanceToInstance(state.artcc) as Artcc;
      const parentFacility = action.payload.getParentFacility(newArtcc);
      parentFacility.childFacilities = parentFacility.childFacilities.filter((f) => f.id !== action.payload.id);
      state.artcc = newArtcc;
    },
    saveFacility: (state, action: PayloadAction<Facility>) => {
      state.artcc!.getFacility(action.payload.id).populate(action.payload);
    },
    addVideoMap: (state, action: PayloadAction<VideoMap>) => {
      state.artcc!.videoMaps.push(action.payload);
    },
    saveVideoMap: (state, action: PayloadAction<VideoMap>) => {
      state.artcc!.getVideoMap(action.payload.id).populate(action.payload);
    },
    updateTransceivers: (state, action: PayloadAction<Transceiver[]>) => {
      state.artcc!.transceivers = action.payload;
    },
    updateAliasDate: (state) => {
      const artcc = state.artcc!;
      artcc.aliasesLastUpdatedAt = new Date();
    },
    addAutoAtcRule: (state, action: PayloadAction<AutoAtcRule>) => {
      state.artcc!.autoAtcRules.push(action.payload);
    },
    deleteAutoAtcRule: (state, action: PayloadAction<AutoAtcRule>) => {
      const newArtcc = instanceToInstance(state.artcc) as Artcc;
      newArtcc.autoAtcRules = newArtcc.autoAtcRules.filter((r) => r.id !== action.payload.id);
      newArtcc.autoAtcRules.forEach((r) => {
        r.exclusionaryRules = r.exclusionaryRules.filter((e) => e !== action.payload.id);
      });
      state.artcc = newArtcc;
    },
    saveAutoAtcRule: (state, action: PayloadAction<AutoAtcRule>) => {
      state.artcc!.autoAtcRules.find((r) => r.id === action.payload.id)!.populate(action.payload);
    },
  },
});

export const {
  setLoaded,
  resetConfirmOverwrite,
  addFacility,
  addAutoAtcRule,
  deleteFacility,
  saveFacility,
  addVideoMap,
  saveVideoMap,
  saveAutoAtcRule,
  deleteAutoAtcRule,
  updateAliasDate,
  updateTransceivers,
} = artccSlice.actions;

export default artccSlice.reducer;

export const artccSelector = (state: RootState) => state.artcc.artcc!;
export const confirmOverwriteSelector = (state: RootState) => state.artcc.confirmOverwrite;
export const loadedSelector = (state: RootState) => state.artcc.loaded;
export const videoMapsSelector = (state: RootState) => state.artcc.artcc!.videoMaps;
