import { createTrainingScenarioAsync } from "@vatsim-vnas/js-libs/api/data";
import { Scenario } from "@vatsim-vnas/js-libs/models/training";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, UploadFileButton } from "src/components/ui";
import { addScenario, artccSelector, useAppDispatch, useAppSelector } from "src/redux";

interface AddTrainingScenarioModalProps {
  show: boolean;
  onClose: (newScenario?: Scenario) => void;
}

const CONVERTER_URL = "https://atctrainer.collinkoldoff.dev/api/converter/scenario";

function AddTrainingScenarioModal({ show, onClose }: Readonly<AddTrainingScenarioModalProps>) {
  const artccId = useAppSelector(artccSelector).id;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: new Scenario(artccId),
    validationSchema: Scenario.schema,
    onSubmit: onClose,
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  const handleSelectFiles = async (files: FileList | File | undefined) => {
    await Promise.all(
      Array.from(files as FileList).map(async (file: File) => {
        if (file.size > 0) {
          const data = new FormData();
          data.append("file", file);
          data.append("artccId", artccId);
          const res = await fetch(CONVERTER_URL, {
            method: "POST",
            body: data,
          });
          if (res.ok) {
            const scenario = (await res.json()) as Scenario;
            if (formik.values.name) {
              scenario.name = formik.values.name;
            }
            const createRes = await createTrainingScenarioAsync(scenario);
            if (createRes.ok) {
              dispatch(addScenario(scenario));
              toast.success("Successfully converted scenario file");
              navigate(`/training/scenarios/${scenario.id}`);
            } else {
              toast.error(`Failed to create scenario file: ${res.statusText}`);
            }
          } else {
            toast.error(`Failed to convert scenario file: ${res.statusText}`);
          }
        }
      }),
    );
  };

  return (
    <Modal show={show} onHide={() => onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Scenario</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Input label="Name" name="name" formik={formik} placeholder="BOS Ground 1" allowLowercase />
          <hr />
          <UploadFileButton
            label="Convert Legacy File"
            accept=".air,.txt,.json"
            showFileName={false}
            multiple
            onSelect={handleSelectFiles}
          />
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid}>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddTrainingScenarioModal;
