import { createTrainingAirportAsync, uploadAirportMapAsync } from "@vatsim-vnas/js-libs/api/data";
import { TrainingAirport, TrainingAirportSummary } from "@vatsim-vnas/js-libs/models/training";
import React, { useMemo, useState } from "react";
import { ButtonGroup, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddIconButton, DeleteIconButton, EditIconButton, Table, TableNoRows } from "src/components/ui";
import { AddTrainingAirportModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import {
  addAirport,
  deleteTrainingAirport,
  trainingAirportSummariesSelector,
  trainingScenarioSummariesSelector,
  useAppDispatch,
  useAppSelector,
} from "src/redux";

function Airports() {
  const scenarios = useAppSelector(trainingScenarioSummariesSelector);
  const allAirports = useAppSelector(trainingAirportSummariesSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<TrainingAirportSummary>>({ show: false });

  const visibleAirports = useMemo(
    () => allAirports.filter((s) => s.id.toLowerCase().includes(search.toLowerCase())),
    [allAirports, search],
  );

  const handleAdd = async (newAirport?: TrainingAirport, file?: File) => {
    if (newAirport && file) {
      const createRes = await createTrainingAirportAsync(newAirport);
      if (createRes.ok) {
        const mapRes = await uploadAirportMapAsync(newAirport.artccId, newAirport.id, file);
        if (mapRes.ok) {
          dispatch(
            addAirport({
              id: newAirport.id,
              artccId: newAirport.artccId,
              lastUpdatedAt: newAirport.lastUpdatedAt,
            }),
          );
          navigate(`airports/${newAirport.id}`);
          toast.success("Successfully created airport");
        } else {
          toast.error(`Failed to upload airport map: ${mapRes.statusText}`);
          return;
        }
      } else {
        toast.error(`Failed to create airport: ${createRes.statusText}`);
        return;
      }
    }
    setShowAddModal(false);
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteTrainingAirport(deleteModalSpec.item!.id));
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Training Airports</Card.Title>
          <div className="card-tools d-flex">
            <input
              className="form-control d-inline-block w-auto mr-3"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />
            <AddIconButton text="Add Airport" onClick={() => setShowAddModal(true)} />
          </div>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Scenario Count</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {visibleAirports.map((airport) => (
                <tr key={airport.id}>
                  <td>{airport.id}</td>
                  <td>{scenarios.filter((s) => s.primaryAirportId === airport.id).length}</td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => navigate(`airports/${airport.id}`)} />
                      <DeleteIconButton
                        disabled={scenarios.some((s) => s.primaryAirportId === airport.id)}
                        onClick={() => setDeleteModalSpec({ show: true, item: airport, itemName: airport.id })}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows rows={visibleAirports} text="No Training Airports found" />
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer />
      </Card>
      <AddTrainingAirportModal show={showAddModal} onClose={handleAdd} />
      <DeleteModal show={deleteModalSpec.show} itemName={deleteModalSpec.itemName} onClose={handleDelete} />
    </>
  );
}

export default Airports;
