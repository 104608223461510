import { Artcc } from "@vatsim-vnas/js-libs/models/facilities";
import { Scenario, ScenarioAtc } from "@vatsim-vnas/js-libs/models/training";
import { CanDeleteSpec } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  AddIconButton,
  CommaListInput,
  DeleteIconButton,
  SelectInput,
  Switch,
  Table,
  TableNoRows,
} from "src/components/ui";
import { DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { artccSelector, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface AtcConfigurationProps {
  formik: FormikProps<Scenario>;
  artccs: Artcc[];
}

function AtcConfiguration({ formik, artccs }: Readonly<AtcConfigurationProps>) {
  const artcc = useAppSelector(artccSelector);
  const allAtc = formik.values.atc;
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec>({ show: false });

  const handleDeleteAtc = (atc: ScenarioAtc) => {
    const autoTrackAircraft = formik.values.aircraft.filter(
      (a) => a.autoTrackConditions?.positionId === atc.positionId,
    );
    const autoTrackGenerators = formik.values.aircraftGenerators.filter(
      (a) => a.autoTrackConfiguration?.positionId === atc.positionId,
    );

    const errors: string[] = [];
    autoTrackAircraft.forEach((a) =>
      errors.push(`Aircraft ${a.aircraftId} is configured to be auto-tracked by this position`),
    );
    autoTrackGenerators.forEach((a) =>
      errors.push(`Arrival Generator for runway ${a.runway} is configured to be auto-tracked by this position`),
    );

    if (!!atc.positionId && errors.length) {
      const positionName = artccs
        .find((a) => a.id === atc.artccId)!
        .getFacility(atc.facilityId)
        .positions.find((p) => p.id === atc.positionId)?.name;
      setDeleteModalSpec({ itemName: positionName, show: true, canDeleteSpec: new CanDeleteSpec(errors) });
    } else {
      deleteFromFormikArray(formik, "atc", atc);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Scenario ATC</Card.Title>
        <div className="card-tools">
          <AddIconButton
            text="Add ATC"
            onClick={() => {
              const newAtc = new ScenarioAtc();
              newAtc.artccId = artcc.id;
              newAtc.facilityId = undefined!;
              newAtc.positionId = undefined!;
              addToFormikArray(formik, "atc", newAtc);
            }}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>ARTCC</th>
                    <th>Facility</th>
                    <th>Position</th>
                    <th>Auto Connect</th>
                    <th>Auto Track Airports</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allAtc.map((atc, i) => (
                    <tr key={atc.id}>
                      <td>
                        <SelectInput
                          formik={formik}
                          name={`atc[${i}].artccId`}
                          options={artccs.map((a) => (
                            <option key={a.id} value={a.id}>
                              {a.id}
                            </option>
                          ))}
                          valueProcessor={(value) => {
                            formik.setFieldValue(`atc[${i}].facilityId`, undefined);
                            formik.setFieldValue(`atc[${i}].positionId`, undefined);
                            return value;
                          }}
                          disabled={
                            !!atc.positionId &&
                            (formik.values.aircraftGenerators.some(
                              (a) => a.autoTrackConfiguration?.positionId === atc.positionId,
                            ) ||
                              formik.values.aircraft.some((a) => a.autoTrackConditions?.positionId === atc.positionId))
                          }
                        />
                      </td>
                      <td>
                        <SelectInput
                          formik={formik}
                          name={`atc[${i}].facilityId`}
                          options={
                            !!artccs.length &&
                            artccs
                              .find((a) => a.id === atc.artccId)!
                              .getAllFacilities()
                              .map((a) => (
                                <option key={a.id} value={a.id}>
                                  {a.name}
                                </option>
                              ))
                          }
                          valueProcessor={(value) => {
                            formik.setFieldValue(`atc[${i}].positionId`, undefined);
                            return value;
                          }}
                          disabled={
                            !!atc.positionId &&
                            (formik.values.aircraftGenerators.some(
                              (a) => a.autoTrackConfiguration?.positionId === atc.positionId,
                            ) ||
                              formik.values.aircraft.some((a) => a.autoTrackConditions?.positionId === atc.positionId))
                          }
                        />
                      </td>
                      <td>
                        <SelectInput
                          formik={formik}
                          name={`atc[${i}].positionId`}
                          options={
                            atc.facilityId &&
                            !!artccs.length &&
                            artccs
                              .find((a) => a.id === atc.artccId)!
                              .getFacility(atc.facilityId)
                              .positions.map((p) => (
                                <option key={p.id} value={p.id}>
                                  {p.name}
                                </option>
                              ))
                          }
                          disabled={
                            !!atc.positionId &&
                            (formik.values.aircraftGenerators.some(
                              (a) => a.autoTrackConfiguration?.positionId === atc.positionId,
                            ) ||
                              formik.values.aircraft.some((a) => a.autoTrackConditions?.positionId === atc.positionId))
                          }
                        />
                      </td>
                      <td className="justify-content-center">
                        <Switch label="" name={`atc[${i}].autoConnect`} formik={formik} />
                      </td>
                      <td>
                        <CommaListInput
                          formik={formik}
                          name={`atc[${i}].autoTrackAirportIds`}
                          placeholder="BOS, BED..."
                          tableCell
                        />
                      </td>
                      <td>
                        <DeleteIconButton
                          onClick={() => {
                            handleDeleteAtc(atc);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  <TableNoRows rows={allAtc} text="No Scenario ATC defined" />
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <DeleteModal
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        itemName={deleteModalSpec.itemName}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
        show={deleteModalSpec.show}
      />
    </Card>
  );
}

export default AtcConfiguration;
