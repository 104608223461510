import { Response } from "@vatsim-vnas/js-libs/models/api";
import { FormikErrors, FormikProps, FormikTouched, getIn } from "formik";
import { RefObject } from "react";
import { toast } from "react-toastify";

export type FileUpload = File | FileList | undefined;

export const addWindowClass = (classList: string) => {
  const window = document?.getElementById("root");
  if (window) {
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList: string) => {
  const window = document?.getElementById("root");
  if (window) {
    window.classList.remove(classList);
  }
};

export const addToFormikArray = <F, T>(
  formik: FormikProps<F>,
  path: string,
  newItem: T,
  tableScrollRef?: RefObject<HTMLDivElement>,
  scrollUp = false,
  toFirstElement = false,
) => {
  let newArray;
  if (toFirstElement) {
    newArray = [newItem, ...getIn(formik.values, path)];
  } else {
    newArray = [...getIn(formik.values, path), newItem];
  }
  formik.setFieldValue(path, newArray);
  if (tableScrollRef?.current) {
    const tableScroll = tableScrollRef.current;
    setTimeout(() => {
      tableScroll.scrollTop = scrollUp ? 0 : tableScroll.scrollHeight;
    });
  }
  return newArray.length - 1;
};

export const deleteFromFormikArray = <F, T>(formik: FormikProps<F>, path: string, item: T) => {
  const newArray = [...getIn(formik.values, path)];
  const index = newArray.indexOf(item);
  newArray.splice(index, 1);

  const touched = { ...formik.touched };
  const touchedField = getIn(touched, path);
  if (Array.isArray(touchedField)) {
    touchedField.splice(index, 1);
    formik.setTouched(touched);
  }

  formik.setFieldValue(path, newArray, true);
};

export const reorderFormikArray = <F>(formik: FormikProps<F>, path: string, index: number, up: boolean) => {
  const newArray = [...getIn(formik.values, path)];
  const item = newArray[index];
  const secondIndex = index + (up ? -1 : 1);
  newArray.splice(index, 1);
  newArray.splice(secondIndex, 0, item);

  const firstTouched = getIn(formik.touched, `${path}[${index}]`);
  const secondTouched = getIn(formik.touched, `${path}[${secondIndex}]`);
  formik.setFieldTouched(`${path}[${index}]`, secondTouched);
  formik.setFieldTouched(`${path}[${secondIndex}]`, firstTouched);

  formik.setFieldValue(path, newArray, true);
};

export const hasTouchedError = <F>(formik: FormikProps<F>, path: string) => {
  const innerHasTouchedError = (errors: FormikErrors<F>, touched: FormikTouched<F>) => {
    if (!errors) {
      return false;
    }

    const errorKeys = Object.keys(errors);
    let keyIndex = 0;

    const checkKeys = (): boolean => {
      if (keyIndex >= errorKeys.length) {
        return false;
      }

      const key = errorKeys[keyIndex];

      if (typeof getIn(errors, key) === "object") {
        if (innerHasTouchedError(getIn(errors, key), getIn(touched, key))) {
          return true;
        }
      } else if (getIn(touched, key)) {
        return true;
      }

      keyIndex += 1;
      return checkKeys();
    };

    return checkKeys();
  };

  return innerHasTouchedError(getIn(formik.errors, path), getIn(formik.touched, path));
};

export const processResponse = <R, T>(
  res: Response<R>,
  errorMessage?: string,
  successMessage?: string,
  returnValue?: T,
): R | T | undefined => {
  if (res.ok) {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (returnValue !== undefined) {
      return returnValue;
    }
    return res.data!;
  }
  if (errorMessage) {
    toast.error(`${errorMessage}: ${res.statusText}`);
  }
  return undefined;
};

export const pluralize = (text: string, count: number) => {
  return `${text}${count === 1 ? "" : "s"}`;
};
