import { convertMHzToHz, frequencyToString } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, getIn } from "formik";
import React, { ChangeEvent, FocusEvent, useEffect, useId, useState } from "react";
import { Form } from "react-bootstrap";
import * as S from "src/styles/ui";

export interface FrequencyInputProps<F> {
  formik: FormikProps<F>;
  label?: string;
  name: string;
  tableCell?: boolean;
}

function FrequencyInput<F>({ formik, tableCell = false, label = undefined, name }: Readonly<FrequencyInputProps<F>>) {
  const [displayValue, setDisplayValue] = useState(frequencyToString(getIn(formik.values, name)) ?? "");
  const [focused, setFocused] = useState(false);
  const id = useId();

  useEffect(() => {
    if (!focused) {
      setDisplayValue(frequencyToString(getIn(formik.values, name)) ?? "");
    }
  }, [formik]);

  const handleChange = (value: string) => {
    setDisplayValue(value);
    if (value === "") {
      formik.setFieldValue(name, undefined);
    } else {
      formik.setFieldValue(name, convertMHzToHz(parseFloat(value)));
    }
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      {tableCell ? (
        <S.FormControlTableCell
          autoComplete="off"
          isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
          id={id}
          name={name}
          onBlur={(e: FocusEvent) => {
            setFocused(false);
            formik.handleBlur(e);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder="118.000"
          type="number"
          value={displayValue}
        />
      ) : (
        <Form.Control
          autoComplete="off"
          isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
          id={id}
          name={name}
          onBlur={(e) => {
            setFocused(false);
            formik.handleBlur(e);
          }}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => setFocused(true)}
          placeholder="118.000"
          type="number"
          value={displayValue}
        />
      )}

      <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default FrequencyInput;
