import { saveTrainingAirportAsync, uploadAirportMapAsync } from "@vatsim-vnas/js-libs/api/data";
import { TrainingAirport as TrainingAirportModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DownloadIconButton, Input, UploadFileButton, UploadFileButtonHandle } from "src/components/ui";
import { artccSelector, useAppSelector, vnasConfigurationSelector } from "src/redux";
import { processResponse } from "src/utils";

interface AirportConfigurationProps {
  formik: FormikProps<TrainingAirportModel>;
}

function AirportConfiguration({ formik }: Readonly<AirportConfigurationProps>) {
  const id = useParams().id!;
  const artcc = useAppSelector(artccSelector);
  const dataApiBaseUrl = useAppSelector(vnasConfigurationSelector)?.dataApiBaseUrl;
  const airportMapInputRef = useRef<UploadFileButtonHandle>(undefined!);
  const [uploadingAirportMap, setUploadingAirportMap] = useState(false);

  const uploadAirportMap = async (airportMap: File) => {
    setUploadingAirportMap(true);
    const res = await uploadAirportMapAsync(artcc.id, id, airportMap);
    if (res.ok) {
      formik.values.lastUpdatedAt = new Date();
      const saveRes = await saveTrainingAirportAsync(formik.values);
      processResponse(saveRes, "Failed to save changes", "Successfully saved changes");
    } else {
      toast.error(`Failed to upload airport map: ${res.statusText}`);
    }

    setUploadingAirportMap(false);
    airportMapInputRef.current?.clear();
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>General</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>ID</Form.Label>
              <Form.Control disabled value={formik.values.id} />
            </Form.Group>
          </Col>
          <Col lg={3} className="mb-3">
            <Input number formik={formik} name="patternAltitude" placeholder="1000" label="Pattern Altitude (MSL)" />
          </Col>
          <Col lg={3} className="mb-3">
            <Input
              number
              formik={formik}
              name="jetInitialAltitude"
              placeholder="5000"
              label="Jet Initial Altitude (MSL)"
            />
          </Col>
          <Col lg={3}>
            <Row className="ml-1">
              <Form.Label>&nbsp;</Form.Label>
            </Row>
            <UploadFileButton
              label="Upload Airport Map"
              accept=".geojson"
              processing={uploadingAirportMap}
              ref={airportMapInputRef}
              showFileName={false}
              onSelect={(f) => {
                if (f instanceof File) {
                  uploadAirportMap(f);
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Artcc ID</Form.Label>
              <Form.Control disabled value={formik.values.artccId} />
            </Form.Group>
          </Col>
          <Col lg={3} className="mb-3">
            <Input number formik={formik} name="patternSize" placeholder="1" label="Pattern Size (nm)" />
          </Col>
          <Col lg={3} className="mb-3">
            <Input
              number
              formik={formik}
              name="propInitialAltitude"
              placeholder="3000"
              label="Prop Initial Altitude (MSL)"
            />
          </Col>
          <Col lg={3}>
            <Row className="ml-1">
              <Form.Label>&nbsp;</Form.Label>
            </Row>
            <DownloadIconButton url={`${dataApiBaseUrl}/training/airports/${id}/map`} text="Download Airport Map" />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default AirportConfiguration;
