import {
  addUserRoleAsync,
  deleteUserRoleAsync,
  getUserRolesAsync,
  updateUserRoleAsync,
} from "@vatsim-vnas/js-libs/api/data";
import { UserRole } from "@vatsim-vnas/js-libs/models/authorization";
import { ArtccRole, artccRoleToString } from "@vatsim-vnas/js-libs/models/data-api";
import { dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Card, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  LoadingCard,
  SortSpec,
  SortableTableHeader,
  Table,
  TableColumn,
  TableNoRows,
} from "src/components/ui";
import { DeleteModal, DeleteModalSpec, UserModalSpec, UserRoleModal } from "src/components/ui/modal";
import { useAppSelector, userSelector } from "src/redux";

function sortFunction(sortSpec: SortSpec, a: UserRole, b: UserRole) {
  const roleA = sortSpec.ascending ? a : b;
  const roleB = sortSpec.ascending ? b : a;

  switch (sortSpec.column) {
    case "CID":
      return parseInt(roleA.userId, 10) - parseInt(roleB.userId, 10);
    case "First Name":
      return roleA.firstName.localeCompare(roleB.firstName);
    case "Last Name":
      return roleA.lastName.localeCompare(roleB.lastName);
    case "ARTCC":
      return roleA.artccId.localeCompare(roleB.artccId);
    case "Role":
      return (roleA.role ?? "").localeCompare(roleB.role ?? "");
    case "Added By":
      return roleA.addedBy.localeCompare(roleB.addedBy);
    default:
      return new Date(roleA.addedAt).getTime() - new Date(roleB.addedAt).getTime();
  }
}

const getUserColumns = (canEdit: boolean) => {
  const columns: TableColumn[] = [
    { label: "CID", sortable: true },
    { label: "First Name", sortable: true },
    { label: "Last Name", sortable: true },
    { label: "ARTCC", sortable: true },
    { label: "Date Added", sortable: true },
    { label: "Role", sortable: true },
    { label: "Added By", sortable: true },
  ];
  if (canEdit) {
    columns.push({ label: "Actions", sortable: false, className: "w-0" });
  }
  return columns;
};

function UserManagement() {
  const user = useAppSelector(userSelector);
  const userCanEditUsers = useMemo(
    () => user.isAdmin || user.hasRole(ArtccRole.Administrator) || user.hasRole(ArtccRole.TrainingAdministrator),
    [user],
  );
  const [admins, setAdmins] = useState<UserRole[]>();
  const [userRoles, setUserRoles] = useState<UserRole[]>();
  const [userModalSpec, setUserModalSpec] = useState<UserModalSpec>({ show: false });
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<UserRole>>({ show: false });
  const [adminSort, setAdminSort] = useState<SortSpec>({ column: "Date Added", ascending: false });
  const [userSort, setUserSort] = useState<SortSpec>({ column: "Date Added", ascending: false });

  useEffect(() => {
    (async () => {
      const res = await getUserRolesAsync();
      if (res.ok) {
        setAdmins(res.data!.filter((u) => u.isAdmin).sort((a, b) => sortFunction(adminSort, a, b)));
        setUserRoles(
          res
            .data!.filter(
              (u) =>
                !u.isAdmin &&
                (user.isAdmin ||
                  user.hasRole(ArtccRole.Administrator, u.artccId) ||
                  (user.hasRole(ArtccRole.TrainingAdministrator, u.artccId) && u.role === ArtccRole.TrainingStaff) ||
                  (user.hasRole(ArtccRole.FacilityEngineer, u.artccId) && u.role === ArtccRole.FacilityEngineer)),
            )
            .sort((a, b) => sortFunction(userSort, a, b)),
        );
      } else {
        toast.error(`Failed to get users: ${res.statusText}`);
        setAdmins([]);
        setUserRoles([]);
      }
    })();
  }, []);

  useEffect(() => {
    if (admins) {
      setAdmins([...admins].sort((a, b) => sortFunction(adminSort, a, b)));
    }
  }, [adminSort]);

  useEffect(() => {
    if (userRoles) {
      setUserRoles([...userRoles].sort((a, b) => sortFunction(userSort, a, b)));
    }
  }, [userSort]);

  const handleAddOrEditUser = async (userRole: UserRole | undefined) => {
    if (userRole) {
      if (userModalSpec.isAdding) {
        const res = await addUserRoleAsync(userRole);
        if (res.ok) {
          toast.success("Successfully added user");
          setUserRoles([...userRoles!, userRole]);
        } else {
          toast.error(`Failed to add user: ${res.statusText}`);
        }
      } else {
        const res = await updateUserRoleAsync(userRole);
        if (res.ok) {
          toast.success("Successfully edited user");
          const newUserRoles = [...userRoles!];
          const index = newUserRoles.findIndex((u) => u.id === userRole.id);
          newUserRoles[index] = userRole;
          setUserRoles(newUserRoles);
        } else {
          toast.error(`Failed to edit user: ${res.statusText}`);
        }
      }
    }
    setUserModalSpec({ ...userModalSpec, show: false });
  };

  const handleDelete = async (confirm: boolean) => {
    if (confirm) {
      const res = await deleteUserRoleAsync(deleteModalSpec.item!.id);
      if (res.ok) {
        toast.success("Successfully deleted user");
        setUserRoles([...userRoles!].filter((u) => u.id !== deleteModalSpec.item!.id));
      } else {
        toast.error(`Failed to delete user: ${res.statusText}`);
      }
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <h1 className="content-header">User Management</h1>
      <section className="content">
        <Container fluid>
          {user.isAdmin && (
            <div>
              {admins !== undefined ? (
                <Card>
                  <Card.Header>
                    <Card.Title>Administrators</Card.Title>
                    <div className="card-tools">
                      <AddIconButton
                        text="Add Administrator"
                        onClick={() =>
                          setUserModalSpec({ isAdding: true, show: true, userRole: UserRole.create(user, true) })
                        }
                      />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table>
                      <SortableTableHeader
                        columns={[
                          { label: "CID", sortable: true },
                          { label: "First Name", sortable: true },
                          { label: "Last Name", sortable: true },
                          { label: "Date Added", sortable: true },
                          { label: "Added By", sortable: true },
                          { label: "Actions", sortable: false, className: "w-0" },
                        ]}
                        defaultSortAscending={false}
                        defaultSortColumn="Date Added"
                        onSort={setAdminSort}
                      />
                      <tbody>
                        {admins.map((admin) => (
                          <tr key={admin.id}>
                            <td>{admin.userId}</td>
                            <td>{admin.firstName}</td>
                            <td>{admin.lastName}</td>
                            <td>{dateToZuluString(admin.addedAt)}</td>
                            <td>{admin.addedBy}</td>
                            <td>
                              <ButtonGroup>
                                <EditIconButton
                                  disabled={user.cid === admin.userId}
                                  onClick={() => setUserModalSpec({ show: true, userRole: admin })}
                                />
                                <DeleteIconButton
                                  disabled={user.cid === admin.userId}
                                  onClick={() =>
                                    setDeleteModalSpec({
                                      show: true,
                                      item: admin,
                                      itemName: `${admin.firstName} ${admin.lastName}`,
                                    })
                                  }
                                />
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                  <Card.Footer />
                </Card>
              ) : (
                <LoadingCard text="Loading Administrators" title="Administrators" />
              )}
            </div>
          )}
          {userRoles !== undefined ? (
            <Card>
              <Card.Header>
                <Card.Title>Users</Card.Title>
                <div className="card-tools">
                  {userCanEditUsers && (
                    <AddIconButton
                      text="Add User"
                      onClick={() =>
                        setUserModalSpec({
                          show: true,
                          isAdding: true,
                          userRole: UserRole.create(user, false),
                        })
                      }
                    />
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                <>
                  <h6>
                    <i>
                      ATMs, DATMs, TAs, and FEs will automatically be granted access through their VATUSA role and will
                      not appear in the list below
                    </i>
                  </h6>
                  <Table>
                    <SortableTableHeader
                      columns={getUserColumns(userCanEditUsers)}
                      onSort={setUserSort}
                      defaultSortAscending={false}
                      defaultSortColumn="Date Added"
                    />
                    <tbody>
                      {userRoles.map((userRole) => (
                        <tr key={userRole.id}>
                          <td>{userRole.userId}</td>
                          <td>{userRole.firstName}</td>
                          <td>{userRole.lastName}</td>
                          <td>{userRole.artccId}</td>
                          <td>{dateToZuluString(userRole.addedAt)}</td>
                          <td>{userRole.role && artccRoleToString(userRole.role)}</td>
                          <td>{userRole.addedBy}</td>
                          {userCanEditUsers && (
                            <td>
                              {(user.isAdmin ||
                                user.hasRole(ArtccRole.Administrator, userRole.artccId) ||
                                (user.hasRole(ArtccRole.TrainingAdministrator, userRole.artccId) &&
                                  userRole.role === ArtccRole.TrainingStaff)) && (
                                <ButtonGroup>
                                  <EditIconButton
                                    disabled={user.cid === userRole.userId}
                                    onClick={() => setUserModalSpec({ show: true, userRole })}
                                  />
                                  <DeleteIconButton
                                    disabled={user.cid === userRole.userId}
                                    onClick={() =>
                                      setDeleteModalSpec({
                                        show: true,
                                        item: userRole,
                                        itemName: `${userRole.firstName} ${userRole.lastName}`,
                                      })
                                    }
                                  />
                                </ButtonGroup>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                      <TableNoRows rows={userRoles} text="No Users found" />
                    </tbody>
                  </Table>
                </>
              </Card.Body>
              <Card.Footer />
            </Card>
          ) : (
            <LoadingCard text="Loading Users" title="Users" />
          )}
        </Container>
      </section>
      <UserRoleModal
        show={userModalSpec.show}
        userRole={userModalSpec.userRole}
        isAdding={userModalSpec.isAdding ?? false}
        onClose={handleAddOrEditUser}
      />
      <DeleteModal
        show={deleteModalSpec.show}
        onClose={handleDelete}
        itemName={deleteModalSpec.itemName}
        action="Remove"
      />
    </>
  );
}

export default UserManagement;
