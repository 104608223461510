import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "src/styles/ui";

interface SmallBoxProps {
  count: number | string;
  faIcon?: IconProp;
  icon?: string;
  navigateTo: string;
  title: string;
}

function SmallBox({ count, faIcon = undefined, icon = undefined, navigateTo, title }: Readonly<SmallBoxProps>) {
  return (
    <Link className="small-box bg-info" to={navigateTo}>
      <div className="inner">
        <h3>{count}&nbsp;</h3>
        <p>{title}</p>
      </div>
      <div className="icon">{faIcon ? <FontAwesomeIcon icon={faIcon} /> : <S.Icon className={icon} />}</div>
      <div className="small-box-footer">
        <span className="mr-2">Edit</span>
        <i className="fa fa-arrow-circle-right" />
      </div>
    </Link>
  );
}

export default SmallBox;
