import { ArtccRole } from "@vatsim-vnas/js-libs/models/data-api";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { artccSelector, useAppSelector, userSelector } from "src/redux";

function FacilityDataRoute() {
  const user = useAppSelector(userSelector);
  const artccId = useAppSelector(artccSelector).id;

  if (
    user.isAdmin ||
    user.hasRole(ArtccRole.Administrator, artccId) ||
    user.hasRole(ArtccRole.FacilityEngineer, artccId)
  ) {
    return <Outlet />;
  }
  return <Navigate to="/403" replace />;
}

export default FacilityDataRoute;
