import { faCopy, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { Artcc } from "@vatsim-vnas/js-libs/models/facilities";
import { Scenario, ScenarioAircraft } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  ErrorTableCell,
  IconButton,
  Table,
  TableNoRows,
} from "src/components/ui";
import { ModalSpec, TrainingScenarioAircraftModal, TrainingScenarioImportModal } from "src/components/ui/modal";
import { trainingScenarioSummariesSelector, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface AircraftConfigurationProps {
  formik: FormikProps<Scenario>;
  artccs: Artcc[];
}

function AircraftConfiguration({ formik, artccs }: Readonly<AircraftConfigurationProps>) {
  const showAircraftImport = useAppSelector(trainingScenarioSummariesSelector).length > 1;
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });
  const [showImportModal, setShowImportModal] = useState<boolean>(false);

  const { aircraft } = formik.values;

  const handleAdd = () => {
    const index = addToFormikArray(formik, "aircraft", new ScenarioAircraft());
    setModalSpec({ show: true, index });
  };

  const handleCopy = (ac: ScenarioAircraft) => {
    formik.setFieldValue("aircraft", [...aircraft, ac.copy()]);
    setModalSpec({ show: true, index: aircraft.length });
  };

  const handleDelete = (ac: ScenarioAircraft) => {
    const flightStripConfigs = formik.values.flightStripConfigurations.filter((f) => f.aircraftIds.includes(ac.id));
    flightStripConfigs.forEach((f) => f.aircraftIds.splice(f.aircraftIds.indexOf(ac.id), 1));
    formik.setFieldValue("flightStripConfigurations", flightStripConfigs);
    deleteFromFormikArray(formik, "aircraft", ac);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Aircraft</Card.Title>
        <div className="card-tools">
          {showAircraftImport && (
            <IconButton
              type="secondary"
              className="mr-2"
              icon={faFileImport}
              text="Import"
              onClick={() => setShowImportModal(true)}
            />
          )}
          <AddIconButton text="Add Aircraft" onClick={handleAdd} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Callsign</th>
                    <th>Type</th>
                    <th>Primary Airport</th>
                    <th>Difficulty</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {aircraft.map((ac, i) => (
                    <tr key={ac.id}>
                      <td>
                        <ErrorTableCell
                          formik={formik}
                          displayValue={ac.aircraftId}
                          name={`aircraft[${i}]`}
                          errorMessage="Invalid Aircraft"
                        />
                      </td>
                      <td>{ac.aircraftType}</td>
                      <td>{ac.airportId}</td>
                      <td>{ac.difficulty}</td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <IconButton type="secondary" icon={faCopy} onClick={() => handleCopy(ac)} />
                          <DeleteIconButton onClick={() => handleDelete(ac)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                  <TableNoRows rows={aircraft} text="No Aircraft defined" />
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingScenarioAircraftModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        artccs={artccs}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
      <TrainingScenarioImportModal formik={formik} show={showImportModal} onClose={() => setShowImportModal(false)} />
    </Card>
  );
}

export default AircraftConfiguration;
