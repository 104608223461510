import {
  BeaconCodeBankType,
  Facility,
  StarsBeaconCodeBank,
  beaconCodeBankTypeToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BeaconCodeInputTableCell,
  DeleteIconButton,
  Input,
  SelectInput,
  Table,
  TableHeader,
  TableNoRows,
} from "src/components/ui";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface BeaconCodeBanksProps {
  beaconCodeBanks: StarsBeaconCodeBank[];
  formik: FormikProps<Facility>;
}

function BeaconCodeBanks({ beaconCodeBanks, formik }: Readonly<BeaconCodeBanksProps>) {
  const tableRef = useRef<HTMLDivElement>(undefined!);

  return (
    <>
      <Row>
        <TableHeader
          label="Beacon Code Allocation"
          addButtonLabel="Add Bank"
          onAdd={() =>
            addToFormikArray(formik, "starsConfiguration.beaconCodeBanks", new StarsBeaconCodeBank(), tableRef)
          }
        />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table maxHeight={500} ref={tableRef}>
            <thead>
              <tr>
                <th>Start</th>
                <th>End</th>
                <th>Type</th>
                <th>Subset</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {beaconCodeBanks.map((bank, i) => (
                <tr key={bank.id}>
                  <td>
                    <BeaconCodeInputTableCell
                      formik={formik}
                      name={`starsConfiguration.beaconCodeBanks[${i}].start`}
                      placeholder="1301"
                    />
                  </td>
                  <td>
                    <BeaconCodeInputTableCell
                      formik={formik}
                      name={`starsConfiguration.beaconCodeBanks[${i}].end`}
                      placeholder="1377"
                    />
                  </td>
                  <td>
                    <SelectInput
                      formik={formik}
                      name={`starsConfiguration.beaconCodeBanks[${i}].type`}
                      options={getEnumOptions(BeaconCodeBankType, beaconCodeBankTypeToString)}
                    />
                  </td>
                  <td>
                    <Input
                      tableCell
                      number
                      name={`starsConfiguration.beaconCodeBanks[${i}].subset`}
                      formik={formik}
                      placeholder="1"
                    />
                  </td>
                  <td>
                    <DeleteIconButton
                      onClick={() => deleteFromFormikArray(formik, "starsConfiguration.beaconCodeBanks", bank)}
                    />
                  </td>
                </tr>
              ))}
              <TableNoRows rows={beaconCodeBanks} text="No Beacon Code Banks defined" />
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default BeaconCodeBanks;
