import { Facility, Rpc } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { DeleteIconButton, EditIconButton, ErrorTableCell, Table, TableHeader, TableNoRows } from "src/components/ui";
import { ModalSpec, RpcModal } from "src/components/ui/modal";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface RpcsProps {
  formik: FormikProps<Facility>;
  rpcs: Rpc[];
}

function Rpcs({ formik, rpcs }: Readonly<RpcsProps>) {
  const tableRef = useRef<HTMLDivElement>(undefined!);
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  const handleAddRpc = () => {
    const index = addToFormikArray(formik, "starsConfiguration.rpcs", new Rpc(), tableRef);
    setModalSpec({ show: true, index });
  };

  return (
    <>
      <Row>
        <TableHeader label="Runway Pair Configurations" addButtonLabel="Add RPC" onAdd={handleAddRpc} />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table maxHeight={500} ref={tableRef}>
            <thead>
              <tr>
                <th>Airport</th>
                <th>Master Runway</th>
                <th>Slave Runway</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rpcs.map((rpc, i) => (
                <tr key={rpc.id}>
                  <td>
                    <ErrorTableCell
                      formik={formik}
                      displayValue={rpc.airportId}
                      name={`starsConfiguration.rpcs[${i}]`}
                      errorMessage="Invalid RPC"
                    />
                  </td>
                  <td>{rpc.masterRunway.runwayId}</td>
                  <td>{rpc.slaveRunway.runwayId}</td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                      <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "starsConfiguration.rpcs", rpc)} />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows rows={rpcs} text="No Runway Pair Configurations defined" />
            </tbody>
          </Table>
        </Col>
      </Row>
      <RpcModal
        show={modalSpec.show}
        formik={formik}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </>
  );
}

export default Rpcs;
