import { TrainingAirport, TrainingAirportCustomWaypoint } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { DeleteIconButton, Input, Table, TableHeader } from "src/components/ui";
import { deleteFromFormikArray } from "src/utils";

interface TrainingAirportCustomApproachModalProps {
  show: boolean;
  index: number | undefined;
  formik: FormikProps<TrainingAirport>;
  onClose: () => void;
}

function TrainingAirportCustomApproachModal({
  show,
  index,
  formik,
  onClose,
}: Readonly<TrainingAirportCustomApproachModalProps>) {
  if (index === undefined) {
    return undefined;
  }

  const approach = formik.values.customApproaches[index];

  if (!approach) {
    return undefined;
  }

  return (
    <Modal show={show} onHide={() => onClose()} size="xl" backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Custom Approach</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row className="mt-3">
          <Col lg={6}>
            <Input formik={formik} name={`customApproaches[${index}].name`} label="Name" placeholder="LV33L" />
          </Col>
          <Col lg={6}>
            <Input formik={formik} name={`customApproaches[${index}].runwayId`} label="Runway" placeholder="33L" />
          </Col>
        </Row>
        <Row className="mt-3">
          <TableHeader
            label="Waypoints"
            onAdd={() => {
              const newWaypoints = [...approach.waypoints];
              newWaypoints.push(new TrainingAirportCustomWaypoint());
              formik.setFieldValue(`customApproaches[${index}].waypoints`, newWaypoints);
            }}
            addButtonLabel="Add Waypoint"
          />
        </Row>
        <Row className="mt-2">
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Altitude</th>
                  <th>Speed</th>
                  <th className="w-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {approach.waypoints.map((waypoint, i) => (
                  <tr key={waypoint.id}>
                    <td>
                      <Input
                        tableCell
                        formik={formik}
                        name={`customApproaches[${index}].waypoints[${i}].name`}
                        placeholder="MYNOT"
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        number
                        formik={formik}
                        name={`customApproaches[${index}].waypoints[${i}].location.lat`}
                        placeholder="42.285503"
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        number
                        formik={formik}
                        name={`customApproaches[${index}].waypoints[${i}].location.lon`}
                        placeholder="-70.750553"
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        number
                        formik={formik}
                        name={`customApproaches[${index}].waypoints[${i}].altitude`}
                        placeholder="3000"
                        useUndefinedForEmpty
                      />
                    </td>
                    <td>
                      <Input
                        tableCell
                        number
                        formik={formik}
                        name={`customApproaches[${index}].waypoints[${i}].speed`}
                        placeholder="210"
                        useUndefinedForEmpty
                      />
                    </td>
                    <td>
                      <DeleteIconButton
                        onClick={() => deleteFromFormikArray(formik, `customApproaches[${index}].waypoints`, waypoint)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingAirportCustomApproachModal;
