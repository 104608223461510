import { TrainingAirport } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Input } from "src/components/ui";

interface TrainingAirportRadarVectorDepartureModalProps {
  show: boolean;
  index: number | undefined;
  formik: FormikProps<TrainingAirport>;
  onClose: () => void;
}

function TrainingAirportRadarVectorDepartureModal({
  show,
  index,
  formik,
  onClose,
}: Readonly<TrainingAirportRadarVectorDepartureModalProps>) {
  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Radar Vector SID</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row className="mt-3">
          <Col>
            <Input formik={formik} name={`vectorDepartures[${index}].sid`} label="SID" placeholder="LOGAN#" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input formik={formik} name={`vectorDepartures[${index}].runway`} label="Runway" placeholder="22L" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input
              number
              formik={formik}
              name={`vectorDepartures[${index}].heading`}
              label="Heading"
              placeholder="140"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input
              formik={formik}
              name={`vectorDepartures[${index}].dmeSource`}
              label="DME Source"
              placeholder="BOS"
              useUndefinedForEmpty
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input
              number
              formik={formik}
              name={`vectorDepartures[${index}].dmeDistance`}
              label="DME Distance"
              placeholder="0.5"
              useUndefinedForEmpty
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingAirportRadarVectorDepartureModal;
