import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  deleteTrainingAirportAsync,
  deleteTrainingScenarioAsync,
  deleteTrainingWeatherAsync,
  getArtccsTrainingAirportSummariesAsync,
  getArtccsTrainingScenarioSummariesAsync,
  getArtccsTrainingWeatherAsync,
} from "@vatsim-vnas/js-libs/api/data";
import { ScenarioSummary, TrainingAirportSummary, TrainingWeather } from "@vatsim-vnas/js-libs/models/training";
import { toast } from "react-toastify";
import { processResponse } from "src/utils";
import { RootState } from "../store";

interface TrainingState {
  scenarioSummaries: ScenarioSummary[];
  airportSummaries: TrainingAirportSummary[];
  weatherScenarios: TrainingWeather[];
}

const initialState: TrainingState = {
  scenarioSummaries: [],
  airportSummaries: [],
  weatherScenarios: [],
};

export const loadArtccsTrainingScenarios = createAsyncThunk(
  "training/loadArtccsTrainingScenarios",
  async (artccId: string) => {
    const res = await getArtccsTrainingScenarioSummariesAsync(artccId);
    return processResponse(res, "Failed to load scenarios");
  },
);

export const deleteScenario = createAsyncThunk("training/deleteScenario", async (scenarioId: string) => {
  const res = await deleteTrainingScenarioAsync(scenarioId);
  return processResponse(res, "Failed to delete scenario", "Successfully deleted scenario", scenarioId);
});

export const loadArtccsTrainingAirportSummaries = createAsyncThunk(
  "training/loadArtccsTrainingAirportSummaries",
  async (artccId: string) => {
    const res = await getArtccsTrainingAirportSummariesAsync(artccId);
    return processResponse(res, "Failed to load airports");
  },
);

export const deleteTrainingAirport = createAsyncThunk("training/deleteTrainingAirport", async (airportId: string) => {
  const res = await deleteTrainingAirportAsync(airportId);
  return processResponse(res, "Failed to delete airport", "Successfully deleted airport", airportId);
});

export const loadArtccsTrainingWeather = createAsyncThunk(
  "training/loadArtccsTrainingWeather",
  async (artccId: string) => {
    const res = await getArtccsTrainingWeatherAsync(artccId);
    return processResponse(res, "Failed to load weather scenarios");
  },
);

export const deleteTrainingWeather = createAsyncThunk("training/deleteTrainingWeather", async (weatherId: string) => {
  const res = await deleteTrainingWeatherAsync(weatherId);
  return processResponse(res, "Failed to delete weather", "Successfully deleted weather", weatherId);
});

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {
    addScenario: (state, action: PayloadAction<ScenarioSummary>) => {
      state.scenarioSummaries.push(action.payload);
    },
    updateScenario: (state, action: PayloadAction<ScenarioSummary>) => {
      state.scenarioSummaries = state.scenarioSummaries.map((s) => (s.id === action.payload.id ? action.payload : s));
    },
    addAirport: (state, action: PayloadAction<TrainingAirportSummary>) => {
      state.airportSummaries.push(action.payload);
    },
    addWeather: (state, action: PayloadAction<TrainingWeather>) => {
      state.weatherScenarios.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadArtccsTrainingScenarios.fulfilled, (state, action) => {
      if (action.payload) {
        state.scenarioSummaries = action.payload;
        state.scenarioSummaries.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    builder.addCase(loadArtccsTrainingScenarios.rejected, (_, action) => {
      toast.error(`Failed to load scenarios: ${action.error.message}`);
    });
    builder.addCase(deleteScenario.fulfilled, (state, action) => {
      if (action.payload) {
        state.scenarioSummaries = state.scenarioSummaries.filter((s) => s.id !== action.payload);
      }
    });
    builder.addCase(deleteScenario.rejected, (_, action) => {
      toast.error(`Failed to delete scenario: ${action.error.message}`);
    });
    builder.addCase(loadArtccsTrainingAirportSummaries.fulfilled, (state, action) => {
      if (action.payload) {
        state.airportSummaries = action.payload;
        state.airportSummaries.sort((a, b) => a.id.localeCompare(b.id));
      }
    });
    builder.addCase(loadArtccsTrainingAirportSummaries.rejected, (_, action) => {
      toast.error(`Failed to load airports: ${action.error.message}`);
    });
    builder.addCase(deleteTrainingAirport.fulfilled, (state, action) => {
      if (action.payload) {
        state.airportSummaries = state.airportSummaries.filter((a) => a.id !== action.payload);
      }
    });
    builder.addCase(deleteTrainingAirport.rejected, (_, action) => {
      toast.error(`Failed to delete airport: ${action.error.message}`);
    });
    builder.addCase(loadArtccsTrainingWeather.fulfilled, (state, action) => {
      if (action.payload) {
        state.weatherScenarios = action.payload;
        state.weatherScenarios.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    builder.addCase(loadArtccsTrainingWeather.rejected, (_, action) => {
      toast.error(`Failed to load weather scenarios: ${action.error.message}`);
    });
    builder.addCase(deleteTrainingWeather.fulfilled, (state, action) => {
      if (action.payload) {
        state.weatherScenarios = state.weatherScenarios.filter((a) => a.id !== action.payload);
      }
    });
    builder.addCase(deleteTrainingWeather.rejected, (_, action) => {
      toast.error(`Failed to delete weather scenario: ${action.error.message}`);
    });
  },
});

export default trainingSlice.reducer;
export const { addScenario, addAirport, updateScenario, addWeather } = trainingSlice.actions;

export const trainingScenarioSummariesSelector = (state: RootState) => state.training.scenarioSummaries;
export const trainingAirportSummariesSelector = (state: RootState) => state.training.airportSummaries;
export const trainingWeatherSelector = (state: RootState) => state.training.weatherScenarios;
