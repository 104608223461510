import {
  AutoAtcDescendVia,
  AutoAtcDescentCrossingRestriction,
  AutoAtcDescentRestriction,
  AutoAtcRule,
} from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input, SelectInput } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import * as yup from "yup";

enum RuleType {
  DescentRestriction = "DescentRestriction",
  DescentCrossingRestriction = "DescentCrossingRestriction",
  DescendVia = "DescendVia",
}

interface AddAutoAtcRuleForm {
  facilityId: string;
  positionId: string;
  name: string;
  ruleType: RuleType;
}

function RuleTypeToString(type: RuleType) {
  switch (type) {
    case RuleType.DescentRestriction:
      return "Descent Restriction";
    case RuleType.DescentCrossingRestriction:
      return "Descent Crossing Restriction";
    case RuleType.DescendVia:
      return "Descend Via";
    default:
      return "";
  }
}

const schema = yup.object().shape({
  name: yup.string().required().max(50).label("Name"),
  facilityId: yup.string().required().label("Facility"),
  positionId: yup.string().required().label("Position"),
});

interface AddAutoAtcRuleModalProps {
  show: boolean;
  onClose: (newRule?: AutoAtcRule) => void;
}

function AddAutoAtcRuleModal({ show, onClose }: Readonly<AddAutoAtcRuleModalProps>) {
  const artcc = useAppSelector(artccSelector);
  const facilities = useMemo(() => artcc.getAllFacilities(), []);

  const formik = useFormik({
    initialValues: { facilityId: artcc.id, ruleType: RuleType.DescentRestriction } as AddAutoAtcRuleForm,
    validationSchema: schema,
    onSubmit: (values) => {
      const newRule = new AutoAtcRule(values.name, values.positionId);
      switch (values.ruleType) {
        case RuleType.DescentRestriction:
          newRule.descentRestriction = new AutoAtcDescentRestriction();
          onClose(newRule);
          return;
        case RuleType.DescentCrossingRestriction:
          newRule.descentCrossingRestriction = new AutoAtcDescentCrossingRestriction();
          onClose(newRule);
          return;
        case RuleType.DescendVia:
          newRule.descendVia = new AutoAtcDescendVia();
          onClose(newRule);
          return;
        default:
          onClose(undefined);
      }
    },
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Rule</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Row>
            <Col>
              <SelectInput
                formik={formik}
                name="facilityId"
                label="Facility"
                disabled
                options={
                  <>
                    {facilities.map((f) => (
                      <option value={f.id} key={f.id}>
                        {f.id}
                      </option>
                    ))}
                  </>
                }
                valueProcessor={(v) => {
                  formik.setFieldValue("positionId", undefined);
                  return v;
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <SelectInput
                formik={formik}
                name="positionId"
                label="Position"
                options={
                  <>
                    {artcc.getFacility(formik.values.facilityId).positions.map((p) => (
                      <option value={p.id} key={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Input formik={formik} name="name" label="Name" placeholder="CCC@250" allowLowercase />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <SelectInput
                formik={formik}
                name="ruleType"
                label="Rule Type"
                options={getEnumOptions(RuleType, RuleTypeToString)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid}>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddAutoAtcRuleModal;
