import { FormikErrors, FormikProps, getIn } from "formik";
import React, { useEffect, useId, useRef, useState } from "react";
import { Form } from "react-bootstrap";

export interface TextareaInputProps<F, T> {
  formik: FormikProps<F>;
  label: string;
  name: string;
  placeholder: string;
  rows?: number;
  valueProcessor?: (value: string[] | undefined) => T[] | undefined;
  displayValueProcessor?: (value: T[]) => string;
}

const findFirstError = (errors: FormikErrors<unknown>): string | undefined => {
  if (!errors) {
    return undefined;
  }

  if (typeof errors === "string") {
    return errors;
  }

  for (const key of Object.keys(errors)) {
    const result = findFirstError((errors as Record<string, object>)[key]);
    if (result) {
      return result;
    }
  }
  return undefined;
};

function TextareaInput<F, T>({
  displayValueProcessor,
  formik,
  label,
  name,
  placeholder,
  rows = 10,
  valueProcessor,
}: Readonly<TextareaInputProps<F, T>>) {
  const [cursor, setCursor] = useState<number | null>(null);
  const [focused, setFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState(getIn(formik.values, name).join("\r\n"));
  const id = useId();
  const ref = useRef<HTMLTextAreaElement>(undefined!);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, getIn(formik.values, name)]);

  useEffect(() => {
    if (!focused) {
      if (displayValueProcessor) {
        setDisplayValue(displayValueProcessor(getIn(formik.values, name)));
      } else {
        setDisplayValue(getIn(formik.values, name).join("\r\n"));
      }
    }
  }, [formik]);

  const handleOnChange = (value: string) => {
    setCursor(ref.current.selectionStart);
    setDisplayValue(value);

    let finalValue: string[] | T[] | undefined = value.split(/\r?\n/);
    if (valueProcessor) {
      finalValue = valueProcessor(finalValue);
    }

    formik.setFieldValue(name, finalValue);
  };

  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control
        as="textarea"
        id={id}
        isInvalid={getIn(formik.errors, name)}
        name={name}
        onBlur={(e) => {
          formik.handleBlur(e);
          setFocused(false);
        }}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={() => setFocused(true)}
        placeholder={placeholder}
        ref={ref}
        rows={rows}
        value={displayValue}
      />
      <Form.Control.Feedback type="invalid">{findFirstError(getIn(formik.errors, name))}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default TextareaInput;
