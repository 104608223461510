import { TrainingAirportHold, TrainingAirport as TrainingAirportModel } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, EditIconButton, ErrorTableCell, Table } from "src/components/ui";
import { ModalSpec, TrainingAirportHoldModal } from "src/components/ui/modal";

import { addToFormikArray, deleteFromFormikArray } from "src/utils/helpers";

interface HoldConfigurationProps {
  formik: FormikProps<TrainingAirportModel>;
}

function HoldConfiguration({ formik }: Readonly<HoldConfigurationProps>) {
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  const handleAdd = () => {
    const index = addToFormikArray(formik, "holds", new TrainingAirportHold());
    setModalSpec({ show: true, index });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Holds</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add Hold" onClick={handleAdd} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Form.Label>Holds</Form.Label>
              <Table>
                <thead>
                  <tr>
                    <th>Fix</th>
                    <th>Course</th>
                    <th>Leg Length</th>
                    <th>Turn Direction</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.holds.map((hold, i) => (
                    <tr key={hold.id}>
                      <td>
                        <ErrorTableCell
                          formik={formik}
                          errorMessage="Invalid Hold"
                          displayValue={hold.fix}
                          name={`holds[${i}]`}
                        />
                      </td>
                      <td>{hold.course}</td>
                      <td>
                        {hold.legLength}
                        {hold.legLengthIsMinutes ? "min" : "nm"}
                      </td>
                      <td>{hold.rightTurns ? "Right Turns" : "Left Turns"}</td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "holds", hold)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingAirportHoldModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default HoldConfiguration;
