import { PrecipitationType, TrainingWeather as TrainingWeatherModel } from "@vatsim-vnas/js-libs/models/training";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Input, SelectInput, TextareaInput } from "src/components/ui";

interface WeatherConfigurationProps {
  formik: FormikProps<TrainingWeatherModel>;
}

function WeatherConfiguration({ formik }: Readonly<WeatherConfigurationProps>) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>General</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Row>
              <Col lg={3}>
                <Input formik={formik} name="name" placeholder="North Ops Heavy Wind" label="Name" allowLowercase />
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Artcc ID</Form.Label>
                  <Form.Control disabled value={formik.values.artccId} />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <SelectInput
                  formik={formik}
                  name="precipitation"
                  label="Precipitation"
                  options={getEnumOptions(PrecipitationType)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <TextareaInput
              label="Metars"
              placeholder="KBOS 191354Z 07009KT 10SM FEW020 BKN045 18/13 A3010 RMK AO2 SLP192 T01780128"
              formik={formik}
              name="metars"
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default WeatherConfiguration;
