import { Artcc, AutoAtcRule } from "@vatsim-vnas/js-libs/models/facilities";
import React, { useMemo, useState } from "react";
import { ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddIconButton, DeleteIconButton, EditIconButton, Table, TableNoRows } from "src/components/ui";
import { AddAutoAtcRuleModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { addAutoAtcRule, artccSelector, deleteAutoAtcRule, saveArtcc, useAppDispatch, useAppSelector } from "src/redux";

function GetRuleFacility(rule: AutoAtcRule, artcc: Artcc) {
  return artcc.getAllFacilities().find((f) => f.positions.find((p) => p.id === rule.positionId))?.id;
}

function AutoAtc() {
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<AutoAtcRule>>({ show: false });

  const search = searchParams.get("search") ?? "";

  const visibleRules = useMemo(
    () => artcc.autoAtcRules.filter((r) => r.name.toLowerCase().includes(search.toLowerCase())),
    [artcc, search],
  );

  const handleAdd = (newRule?: AutoAtcRule) => {
    if (newRule) {
      dispatch(addAutoAtcRule(newRule));
      dispatch(saveArtcc("Successfully added rule"));
      navigate(newRule.id);
    }
    setShowAddModal(false);
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteAutoAtcRule(deleteModalSpec.item!));
      dispatch(saveArtcc("Successfully deleted rule"));
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <h1 className="content-header">Auto ATC Rules</h1>
      <section className="content">
        <Container fluid>
          <Card>
            <Card.Header>
              <Card.Title>Auto ATC Rules</Card.Title>
              <div className="card-tools d-flex gap-3">
                <input
                  className="form-control w-auto"
                  value={search}
                  onChange={(e) =>
                    setSearchParams(
                      (p) => {
                        p.set("search", e.target.value);
                        return p;
                      },
                      { replace: true },
                    )
                  }
                  placeholder="Search..."
                />
                <AddIconButton text="Add Rule" onClick={() => setShowAddModal(true)} />
              </div>
            </Card.Header>
            <Card.Body>
              <Row className="mt-2">
                <Col>
                  <Table>
                    <thead>
                      <tr>
                        <th>Facility</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Phraseology</th>
                        <th className="w-0">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visibleRules.map((rule) => (
                        <tr key={rule.id}>
                          <td>{GetRuleFacility(rule, artcc)}</td>
                          <td>{rule.name}</td>
                          <td>{!rule.isEnabled ? "Disabled" : ""}</td>
                          <td>{rule.toPhraseologyString()}</td>
                          <td>
                            <ButtonGroup>
                              <EditIconButton onClick={() => navigate(rule.id)} />
                              <DeleteIconButton
                                onClick={() => setDeleteModalSpec({ show: true, item: rule, itemName: rule.name })}
                              />
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                      <TableNoRows rows={artcc.autoAtcRules} text="No rules defined" />
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer />
          </Card>
        </Container>
      </section>
      <AddAutoAtcRuleModal show={showAddModal} onClose={handleAdd} />
      <DeleteModal
        show={deleteModalSpec.show}
        itemName={deleteModalSpec.itemName}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={handleDelete}
      />
    </>
  );
}

export default AutoAtc;
