import {
  Facility,
  FlightStripsConfiguration as FlightStripsConfigurationModel,
  StripBay,
  StripBaySpec,
} from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useMemo, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { BaseSwitch, DeleteIconButton, Input, Switch, Table, TableHeader, TableNoRows } from "src/components/ui";
import { DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { artccSelector, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface FlightStripsConfigurationProps {
  formik: FormikProps<Facility>;
}

function FlightStripsConfiguration({ formik }: Readonly<FlightStripsConfigurationProps>) {
  const artcc = useAppSelector(artccSelector);
  const externalBays = useMemo(() => artcc.getAllStripBays().filter((b) => b.facilityId !== formik.values.id), []);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec>({ show: false });
  const { flightStripsConfiguration } = formik.values;

  const handleEnableDisable = () => {
    if (!flightStripsConfiguration) {
      formik.setFieldValue("flightStripsConfiguration", new FlightStripsConfigurationModel());
      return;
    }

    const canDisable = flightStripsConfiguration.canDisable(artcc);
    if (canDisable.canDelete) {
      formik.setFieldValue("flightStripsConfiguration", undefined);
      return;
    }
    setDeleteModalSpec({
      boldItem: false,
      action: "Disable",
      itemName: "flight strips",
      show: true,
      canDeleteSpec: canDisable,
    });
  };

  const handleDeleteBay = (bay: StripBay) => {
    const canDelete = bay.canDelete(artcc);
    if (canDelete.canDelete) {
      deleteFromFormikArray(formik, "flightStripsConfiguration.stripBays", bay);
    } else {
      setDeleteModalSpec({ itemName: bay.name, show: true, canDeleteSpec: canDelete });
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Flight Strips Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <BaseSwitch
              onChange={handleEnableDisable}
              label="Enable Flight Strips"
              checked={!!flightStripsConfiguration}
            />
          </Col>
          <div />
        </Row>
        {flightStripsConfiguration && (
          <>
            <Row className="mt-3">
              <Col md>
                <Switch label="Lock Separators" name="flightStripsConfiguration.lockSeparators" formik={formik} />
              </Col>
              <Col md>
                <Switch
                  label="Display Destination Airports"
                  name="flightStripsConfiguration.displayDestinationAirportIds"
                  formik={formik}
                />
              </Col>
              <Col md>
                <Switch label="Display Barcodes" name="flightStripsConfiguration.displayBarcodes" formik={formik} />
              </Col>
              <Col md>
                <Switch
                  label="Enable Arrival Strips"
                  name="flightStripsConfiguration.enableArrivalStrips"
                  formik={formik}
                />
              </Col>
              <Col md>
                <BaseSwitch
                  label="Enable Separate Departure/Arrival Strip Printers"
                  disabled={!flightStripsConfiguration.enableArrivalStrips}
                  checked={
                    !flightStripsConfiguration.enableArrivalStrips
                      ? false
                      : flightStripsConfiguration.enableSeparateArrDepPrinters
                  }
                  onChange={(e) =>
                    formik.setFieldValue("flightStripsConfiguration.enableSeparateArrDepPrinters", e.target.checked)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <TableHeader
                label="Bays"
                addButtonLabel="Add Bay"
                onAdd={() => addToFormikArray(formik, "flightStripsConfiguration.stripBays", new StripBay())}
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Bay Name</th>
                      <th>Number of Racks</th>
                      <th>Default Rack</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {flightStripsConfiguration.stripBays.map((bay, i) => (
                      <tr key={bay.id}>
                        <td>
                          <Input
                            tableCell
                            name={`flightStripsConfiguration.stripBays[${i}].name`}
                            formik={formik}
                            placeholder="Ground"
                            allowLowercase
                          />
                        </td>
                        <td>
                          <Input
                            tableCell
                            name={`flightStripsConfiguration.stripBays[${i}].numberOfRacks`}
                            formik={formik}
                            number
                            placeholder="3"
                          />
                        </td>
                        <td>
                          <Input
                            tableCell
                            name={`flightStripsConfiguration.stripBays[${i}].defaultRack`}
                            formik={formik}
                            number
                            placeholder="1"
                          />
                        </td>
                        <td>
                          <DeleteIconButton
                            disabled={flightStripsConfiguration.stripBays.length <= 1}
                            onClick={() => handleDeleteBay(bay)}
                          />
                        </td>
                      </tr>
                    ))}
                    <TableNoRows rows={flightStripsConfiguration.stripBays} text="No Bays defined" />
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group>
                  <Form.Label>External Bays</Form.Label>
                  <Typeahead
                    id="external-bays"
                    options={externalBays}
                    flip
                    placeholder="Add external bays..."
                    labelKey={(bay) =>
                      `${(bay as StripBaySpec).facilityId}: ${artcc.getStripBay((bay as StripBaySpec).bayId).name}`
                    }
                    paginate
                    defaultSelected={flightStripsConfiguration.externalBays}
                    multiple
                    onChange={(b) => formik.setFieldValue("flightStripsConfiguration.externalBays", b)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
      <Card.Footer />
      <DeleteModal
        show={deleteModalSpec.show}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
        action={deleteModalSpec.action}
        itemName={deleteModalSpec.itemName}
        boldItem={deleteModalSpec.boldItem}
      />
    </Card>
  );
}

export default FlightStripsConfiguration;
