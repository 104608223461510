import { VideoMap } from "@vatsim-vnas/js-libs/models/video-maps";
import { FormikProps, getIn } from "formik";
import React, { useId, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { artccSelector, useAppSelector } from "src/redux";
import FeedbackLabel from "./FeedbackLabel";

export interface VideoMapInputProps<F> {
  formik: FormikProps<F>;
  name: string;
  label?: string;
}

function VideoMapInput<F>({ formik, name, label = undefined }: Readonly<VideoMapInputProps<F>>) {
  const artcc = useAppSelector(artccSelector);
  const videoMapId = getIn(formik.values, name);
  const videoMap = useMemo(() => (videoMapId ? artcc.getVideoMap(videoMapId) : undefined), [videoMapId]);
  const id = useId();

  const handleChange = (value: VideoMap) => {
    if (!value) {
      formik.setFieldValue(name, "");
    } else {
      formik.setFieldValue(name, value.id);
    }
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <Typeahead
        defaultSelected={videoMap ? [videoMap.name] : undefined}
        flip
        id={id}
        isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
        labelKey="name"
        onBlur={() => formik.setFieldTouched(name)}
        onChange={(v) => handleChange(v[0] as VideoMap)}
        options={artcc.videoMaps}
        paginate
        placeholder="Select video map..."
      />
      <FeedbackLabel formik={formik} name={name} />
    </Form.Group>
  );
}

export default VideoMapInput;
