import { AutoAtcRule as AutoAtcRuleModel } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikHelpers, getIn, useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Navigate, useParams } from "react-router-dom";
import { DescendVia, DescentCrossingRestriction, DescentRestriction } from "src/components/auto-atc";
import { CommaListInput, Input, SaveButtonBar, SelectInput, Switch } from "src/components/ui";
import { artccSelector, saveArtcc, saveAutoAtcRule, setUnsavedData, useAppDispatch, useAppSelector } from "src/redux";
import { Label } from "src/styles/ui";

function AutoAtcRule() {
  const id = useParams().id!;

  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();

  const rule = useMemo(() => artcc.autoAtcRules.find((r) => r.id === id)!, []);
  const facilityId = useMemo(
    () => artcc.getAllFacilities().find((f) => f.positions.find((p) => p.id === rule.positionId))!.id,
    [],
  );

  const handleSubmit = (values: AutoAtcRuleModel, formik: FormikHelpers<AutoAtcRuleModel>) => {
    dispatch(saveAutoAtcRule(values));
    dispatch(saveArtcc("Successfully saved changes"));
    formik.resetForm({ values });
  };

  const formik = useFormik({
    initialValues: rule,
    enableReinitialize: true,
    validationSchema: AutoAtcRuleModel.schema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  if (!rule) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <h1 className="content-header">Auto ATC Rule</h1>
      <section className="content">
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Card.Header>
                <Card.Title>General</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md className="mb-3">
                    <Form.Label>Facility</Form.Label>
                    <Form.Control disabled value={facilityId} />
                  </Col>
                  <Col md className="mb-3">
                    <SelectInput
                      formik={formik}
                      name="positionId"
                      label="Position"
                      options={artcc
                        .getAllFacilities()
                        .find((f) => f.positions.find((p) => p.id === formik.values.positionId))
                        ?.positions.map((p) => (
                          <option value={p.id} key={p.id}>
                            {p.name}
                          </option>
                        ))}
                    />
                  </Col>
                  <Col md className="mb-3">
                    <Input formik={formik} name="name" label="Name" placeholder="CCC@120" allowLowercase />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Switch label="Enabled" name="isEnabled" formik={formik} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Precursor Rules</Form.Label>
                    <Typeahead
                      id="precursorRules"
                      defaultSelected={artcc.autoAtcRules.filter((r) => rule.precursorRules.includes(r.id))}
                      flip
                      labelKey={(option) => (option as AutoAtcRuleModel).name}
                      multiple
                      onChange={(o) =>
                        formik.setFieldValue(
                          "precursorRules",
                          (o as AutoAtcRuleModel[]).map((r) => r.id),
                        )
                      }
                      options={artcc.autoAtcRules.filter(
                        (r) => r.id !== rule.id && !formik.values.exclusionaryRules.includes(r.id),
                      )}
                      paginate
                      placeholder="None"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Exclusionary Rules</Form.Label>
                    <Typeahead
                      id="exclusionaryRules"
                      defaultSelected={artcc.autoAtcRules.filter((r) => rule.exclusionaryRules.includes(r.id))}
                      flip
                      labelKey={(option) => (option as AutoAtcRuleModel).name}
                      multiple
                      onChange={(o) =>
                        formik.setFieldValue(
                          "exclusionaryRules",
                          (o as AutoAtcRuleModel[]).map((r) => r.id),
                        )
                      }
                      options={artcc.autoAtcRules.filter(
                        (r) => r.id !== rule.id && !formik.values.precursorRules.includes(r.id),
                      )}
                      paginate
                      placeholder="None"
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <Card>
              <Card.Header>
                <Card.Title>Criteria</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <CommaListInput
                      placeholder="Match all departures"
                      formik={formik}
                      label="Departures"
                      name="criteria.departures"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <CommaListInput
                      placeholder="Match all destinations"
                      formik={formik}
                      label="Destinations"
                      name="criteria.destinations"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <CommaListInput
                      placeholder="Match all routes"
                      formik={formik}
                      label="Route Substrings"
                      name="criteria.routeSubstrings"
                      allowSpaces
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <CommaListInput
                      placeholder="Exclude no routes"
                      formik={formik}
                      label="Excluded Route Substrings"
                      name="criteria.excludeRouteSubstrings"
                      allowSpaces
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Label>Applicable Aircraft Types</Label>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md>
                    <Switch label="Props" name="criteria.applicableToProps" formik={formik} />
                  </Col>
                  <Col md>
                    <Switch label="Turboprops" name="criteria.applicableToTurboprops" formik={formik} />
                  </Col>
                  <Col md>
                    <Switch label="Jets" name="criteria.applicableToJets" formik={formik} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md className="mb-3">
                    <Input
                      label="Min Altitude"
                      name="criteria.minAltitude"
                      formik={formik}
                      placeholder="None"
                      number
                      useUndefinedForEmpty
                    />
                  </Col>
                  <Col md className="mb-3">
                    <Input
                      label="Max Altitude"
                      name="criteria.maxAltitude"
                      formik={formik}
                      placeholder="None"
                      number
                      useUndefinedForEmpty
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {getIn(formik.errors, "criteria.applicableToProps")}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            {formik.values.descentRestriction && <DescentRestriction formik={formik} />}
            {formik.values.descentCrossingRestriction && <DescentCrossingRestriction formik={formik} />}
            {formik.values.descendVia && <DescendVia formik={formik} />}
            <Card>
              <Card.Header>
                <Card.Title>Phraseology Preview</Card.Title>
              </Card.Header>
              <Card.Body>
                {formik.values.toPhraseologyString() && formik.isValid ? (
                  <h5>{formik.values.toPhraseologyString()}</h5>
                ) : (
                  <div className="text-danger">Error in form</div>
                )}
              </Card.Body>
              <Card.Footer />
            </Card>
            <SaveButtonBar formik={formik} backText="Back to Auto ATC Rules" />
          </Form>
        </Container>
      </section>
    </>
  );
}

export default AutoAtcRule;
