import { Facility, StarsMapGroup } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { CommaListInput, DeleteIconButton, EditIconButton, Table, TableHeader, TableNoRows } from "src/components/ui";
import { MapGroupModal, ModalSpec } from "src/components/ui/modal";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface MapGroupsProps {
  formik: FormikProps<Facility>;
  mapGroups: StarsMapGroup[];
}

function MapGroups({ formik, mapGroups }: Readonly<MapGroupsProps>) {
  const tableRef = useRef<HTMLDivElement>(undefined!);
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  return (
    <>
      <Row>
        <TableHeader
          label="Map Groups"
          addButtonLabel="Add MapGroup"
          onAdd={() => addToFormikArray(formik, "starsConfiguration.mapGroups", new StarsMapGroup(), tableRef)}
        />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table ref={tableRef} maxHeight={500}>
            <thead>
              <tr>
                <th className="w-0">Number</th>
                <th>TCPs</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {mapGroups.map((mapGroup, i) => (
                <tr key={mapGroup.id}>
                  <td>{i + 1}</td>
                  <td>
                    <CommaListInput
                      tableCell
                      name={`starsConfiguration.mapGroups[${i}].tcps`}
                      formik={formik}
                      placeholder="1D, 2S..."
                    />
                  </td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                      <DeleteIconButton
                        onClick={() => deleteFromFormikArray(formik, "starsConfiguration.mapGroups", mapGroup)}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows rows={mapGroups} text="No Map Groups defined" />
            </tbody>
          </Table>
        </Col>
      </Row>
      <MapGroupModal
        formik={formik}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
        show={modalSpec.show}
      />
    </>
  );
}

export default MapGroups;
