import {
  AltitudeConstraintType,
  AutoAtcRule,
  altitudeConstraintTypeToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps, getIn } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Input, SelectInput, Switch } from "../ui";

interface AltitudeConstraintProps {
  formik: FormikProps<AutoAtcRule>;
  baseName: string;
  allowConstraintType?: boolean;
}

function AltitudeConstraint({ formik, baseName, allowConstraintType }: Readonly<AltitudeConstraintProps>) {
  const handleIsLuflChange = (value: boolean) => {
    if (value) {
      formik.setFieldValue(`${baseName}.altitudeConstraint.value`, 18_000);
    } else {
      setTimeout(() => {
        formik.setFieldValue(`${baseName}.altitudeConstraint.luflStationId`, undefined);
        formik.validateField(`${baseName}.altitudeConstraint.luflStationId`);
      });
    }
  };

  return (
    <Row className="mt-3">
      {allowConstraintType && (
        <Col md className="mb-3">
          <SelectInput
            formik={formik}
            name={`${baseName}.altitudeConstraint.constraintType`}
            label="Constraint"
            options={getEnumOptions(AltitudeConstraintType, altitudeConstraintTypeToString)}
          />
        </Col>
      )}
      <Col md className="mb-3">
        <Input
          formik={formik}
          name={`${baseName}.altitudeConstraint.value`}
          label="Altitude"
          placeholder="12000"
          number
          disabled={getIn(formik.values, `${baseName}.altitudeConstraint.isLufl`)}
        />
      </Col>
      <Col md className="mb-3 d-flex align-items-center">
        <Switch
          label="Lowest Usable Flight Level"
          name={`${baseName}.altitudeConstraint.isLufl`}
          formik={formik}
          onChange={handleIsLuflChange}
        />
      </Col>
      <Col md className="mb-3">
        <Input
          formik={formik}
          label="LUFL Altimeter Station ID"
          placeholder="KJFK"
          name={`${baseName}.altitudeConstraint.luflStationId`}
          disabled={!getIn(formik.values, `${baseName}.altitudeConstraint.isLufl`)}
        />
      </Col>
    </Row>
  );
}

export default AltitudeConstraint;
