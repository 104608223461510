import { replaceAllInTrainingScenarioFlightPlansAsync } from "@vatsim-vnas/js-libs/api/data";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ProcessButton } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import { pluralize } from "src/utils";

function FlightplanSearchAndReplace() {
  const artccId = useAppSelector(artccSelector).id;
  const [processing, setProcessing] = useState(false);
  const [search, setSearch] = useState("");
  const [replace, setReplace] = useState("");

  const handleSubmit = async () => {
    setProcessing(true);
    const res = await replaceAllInTrainingScenarioFlightPlansAsync(artccId, search.replace("#", "\\d"), replace);
    if (res.ok) {
      const number = parseInt(await res.text(), 10);
      if (number === 0) {
        toast.success("No flight plans need updating");
      } else {
        toast.success(`Successfully updated ${number} ${pluralize("flightplan", number)}`);
      }
    } else {
      toast.error(`Error updating flightplans: ${res.statusText}`);
    }
    setSearch("");
    setReplace("");
    setProcessing(false);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Flightplan Search and Replace</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form.Group controlId="search">
              <Form.Label>Search</Form.Label>
              <Form.Control
                placeholder="SSOXS#"
                autoComplete="off"
                onChange={(e) => setSearch(e.target.value.toUpperCase())}
                value={search}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="replace">
              <Form.Label>Replace</Form.Label>
              <Form.Control
                placeholder="SSOXS6"
                autoComplete="off"
                onChange={(e) => setReplace(e.target.value.toUpperCase())}
                value={replace}
              />
            </Form.Group>
            <ProcessButton
              disabled={!search || !replace || processing}
              processing={processing}
              onClick={handleSubmit}
              text="Submit"
              className="float-right mt-3"
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default FlightplanSearchAndReplace;
