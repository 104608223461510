import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Facility, TdlsClearanceValue, TdlsSid, TdlsSidTransition } from "@vatsim-vnas/js-libs/models/facilities";
import { ClearanceField, clearanceFieldToString } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { FormikProps, getIn } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import {
  DeleteIconButton,
  IconButton,
  Input,
  OrderButtonPair,
  SelectInput,
  Table,
  TableHeader,
  TableNoRows,
} from "src/components/ui";
import { deleteFromFormikArray } from "src/utils";
import { DeleteModal, DeleteModalSpec } from ".";

interface TdlsModalProps {
  field: ClearanceField | undefined;
  formik: FormikProps<Facility>;
  onClose: () => void;
}

const clearanceFieldToPropertyName = (field: ClearanceField) => {
  const propertyName = `${field.toString()}s`;
  return propertyName[0].toLowerCase() + propertyName.substring(1);
};

const pluralizeClearanceField = (field: ClearanceField) => {
  const string = clearanceFieldToString(field);
  if (field === ClearanceField.DepFreq) {
    return `${string.slice(0, -1)}ies`;
  }
  return `${string}s`;
};

const PLACEHOLDERS = new Map([
  [ClearanceField.Climbout, "CARNASIE CLIMB"],
  [ClearanceField.Climbvia, "CLIMB VIA SID"],
  [ClearanceField.InitialAlt, "5000FT"],
  [ClearanceField.DepFreq, "133.0"],
  [ClearanceField.ContactInfo, "CTC 121.65 TO PUSH"],
  [ClearanceField.LocalInfo, "ADV ATIS"],
]);

function TdlsModal({ field, formik, onClose }: Readonly<TdlsModalProps>) {
  const [selectedSidIndex, setSelectedSidIndex] = useState<number>();
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<TdlsClearanceValue>>({ show: false });

  const tdlsConfiguration = formik.values.tdlsConfiguration!;

  const handleDeleteSid = (sid: TdlsSid) => {
    setSelectedSidIndex(undefined);
    if (tdlsConfiguration.defaultSidId === sid.id) {
      formik.setFieldValue("tdlsConfiguration.defaultSidId", undefined);
      formik.setFieldValue("tdlsConfiguration.defaultTransitionId", undefined);
    }
    deleteFromFormikArray(formik, "tdlsConfiguration.sids", sid);
  };

  const handleDeleteTransition = (transition: TdlsSidTransition) => {
    if (tdlsConfiguration.defaultTransitionId === transition.id) {
      formik.setFieldValue(
        "tdlsConfiguration.defaultTransitionId",
        tdlsConfiguration.sids[selectedSidIndex!].transitions[0].id,
      );
    }
    deleteFromFormikArray(formik, `tdlsConfiguration.sids[${selectedSidIndex}].transitions`, transition);
  };

  const handleDeleteValue = (value: TdlsClearanceValue) => {
    const canDeleteSpec = tdlsConfiguration.canDeleteValue(field!, value.value);
    if (canDeleteSpec.canDelete) {
      deleteFromFormikArray(formik, `tdlsConfiguration.${clearanceFieldToPropertyName(field!)}`, value);
    } else {
      setDeleteModalSpec({
        show: true,
        itemName: value.value,
        canDeleteSpec,
      });
    }
  };

  if (!field) {
    return undefined;
  }

  return (
    <>
      <Modal show onHide={() => onClose()} backdrop="static" size="xl">
        <Modal.Header className="dark-mode">
          <Modal.Title>TDLS {pluralizeClearanceField(field)}</Modal.Title>
        </Modal.Header>
        {field === ClearanceField.Sid ? (
          <Modal.Body className="dark-mode">
            <Row>
              <TableHeader
                label="SIDs"
                addButtonLabel="Add SID"
                onAdd={() => {
                  const newSid = new TdlsSid();
                  const newSids = [...tdlsConfiguration.sids];
                  newSids.push(newSid);
                  formik.setFieldValue("tdlsConfiguration.sids", newSids);
                }}
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>SID</th>
                      <th className="w-0">Transitions</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tdlsConfiguration.sids.map((sid, i) => (
                      <tr key={sid.id}>
                        <td>
                          <Input
                            tableCell
                            formik={formik}
                            name={`tdlsConfiguration.sids[${i}].name`}
                            placeholder="SSOXS6"
                          />
                        </td>
                        <td>
                          <IconButton
                            type={selectedSidIndex === i ? "primary" : "secondary"}
                            icon={faPencil}
                            onClick={() => {
                              if (selectedSidIndex === i) {
                                setSelectedSidIndex(undefined);
                              } else {
                                setSelectedSidIndex(i);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <ButtonGroup>
                            <OrderButtonPair formik={formik} index={i} name="tdlsConfiguration.sids" />
                            <DeleteIconButton onClick={() => handleDeleteSid(sid)} />
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                    <TableNoRows rows={tdlsConfiguration.sids} text="No SIDs defined" />
                  </tbody>
                </Table>
              </Col>
            </Row>
            {selectedSidIndex !== undefined && (
              <>
                <Row>
                  <TableHeader
                    label={`${tdlsConfiguration.sids[selectedSidIndex].name} Transitions`}
                    addButtonLabel="Add Transition"
                    onAdd={() => {
                      const newTransition = new TdlsSidTransition();
                      const newTransitions = [...tdlsConfiguration.sids[selectedSidIndex].transitions];
                      newTransitions.push(newTransition);
                      formik.setFieldValue(`tdlsConfiguration.sids[${selectedSidIndex}].transitions`, newTransitions);
                    }}
                  />
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th>Transition</th>
                          <th>FRP</th>
                          <th>Expect</th>
                          <th>Climbout</th>
                          <th>Climbvia</th>
                          <th>Initial Alt</th>
                          <th>Dep Freq</th>
                          <th>Contact Info</th>
                          <th>Local Info</th>
                          <th className="w-0">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tdlsConfiguration.sids[selectedSidIndex].transitions.map((transition, i) => (
                          <tr key={transition.id}>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].name`}
                                placeholder="SSOXS"
                              />
                            </td>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                placeholder="SSOXS"
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].firstRoutePoint`}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultExpect`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.expects.map((e) => (
                                  <option key={e.id}>{e.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultClimbout`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.climbouts.map((c) => (
                                  <option key={c.id}>{c.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultClimbvia`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.climbvias.map((c) => (
                                  <option key={c.id}>{c.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultInitialAlt`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.initialAlts.map((a) => (
                                  <option key={a.id}>{a.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultDepFreq`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.depFreqs.map((f) => (
                                  <option key={f.id}>{f.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultContactInfo`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.contactInfos.map((c) => (
                                  <option key={c.id}>{c.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <SelectInput
                                formik={formik}
                                name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions[${i}].defaultLocalInfo`}
                                clearOption="NO DEFAULT"
                                options={tdlsConfiguration.localInfos.map((l) => (
                                  <option key={l.id}>{l.value}</option>
                                ))}
                              />
                            </td>
                            <td>
                              <ButtonGroup>
                                <OrderButtonPair
                                  formik={formik}
                                  index={i}
                                  name={`tdlsConfiguration.sids[${selectedSidIndex}].transitions`}
                                />
                                <DeleteIconButton
                                  disabled={i === 0}
                                  onClick={() => handleDeleteTransition(transition)}
                                />
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body className="dark-mode">
            <Row>
              <TableHeader
                label={pluralizeClearanceField(field)}
                onAdd={() => {
                  const newValues: TdlsClearanceValue[] = [
                    ...getIn(formik.values, `tdlsConfiguration.${clearanceFieldToPropertyName(field)}`),
                  ];
                  const newValue = new TdlsClearanceValue();
                  newValue.value = "- - - -";
                  newValues.push(newValue);
                  formik.setFieldValue(`tdlsConfiguration.${clearanceFieldToPropertyName(field)}`, newValues);
                }}
                addButtonLabel={`Add ${clearanceFieldToString(field)}`}
              />
            </Row>
            <Row className="mt-2">
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Option</th>
                      <th className="w-0">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getIn(formik.values, `tdlsConfiguration.${clearanceFieldToPropertyName(field)}`).map(
                      (value: TdlsClearanceValue, i: number) => (
                        <tr key={value.id}>
                          <td>
                            <Input
                              tableCell
                              formik={formik}
                              name={`tdlsConfiguration.${clearanceFieldToPropertyName(field)}[${i}].value`}
                              placeholder={PLACEHOLDERS.get(field)!}
                            />
                          </td>
                          <td>
                            <ButtonGroup>
                              <OrderButtonPair
                                formik={formik}
                                index={i}
                                name={`tdlsConfiguration.${clearanceFieldToPropertyName(field)}`}
                              />
                              <DeleteIconButton onClick={() => handleDeleteValue(value)} />
                            </ButtonGroup>
                          </td>
                        </tr>
                      ),
                    )}
                    <TableNoRows
                      rows={getIn(formik.values, `tdlsConfiguration.${clearanceFieldToPropertyName(field)}`)}
                      text={`No ${pluralizeClearanceField(field)} defined`}
                    />
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
        )}
        <Modal.Footer className="dark-mode">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              onClose();
              setSelectedSidIndex(undefined);
            }}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
      <DeleteModal
        onClose={() => setDeleteModalSpec({ ...deleteModalSpec, show: false })}
        show={deleteModalSpec.show}
        itemName={deleteModalSpec.itemName}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
      />
    </>
  );
}

export default TdlsModal;
