import { FormikProps, getIn } from "formik";
import React, { ChangeEvent, FocusEvent, useEffect, useId, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import * as S from "src/styles/ui";

export interface InputProps<F> {
  allowLowercase?: boolean;
  disabled?: boolean;
  formik: FormikProps<F>;
  label?: string;
  name: string;
  number?: boolean;
  onBlur?: (value: string | undefined) => void;
  placeholder: string;
  style?: React.CSSProperties;
  tableCell?: boolean;
  trim?: boolean;
  valueProcessor?: (value: string | undefined) => string | undefined;
  useUndefinedForEmpty?: boolean;
}

function Input<F>({
  allowLowercase = false,
  disabled = false,
  formik,
  label = undefined,
  name,
  number = false,
  onBlur,
  placeholder,
  style = undefined,
  tableCell = false,
  trim = true,
  valueProcessor = undefined,
  useUndefinedForEmpty = false,
}: Readonly<InputProps<F>>) {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(undefined!);
  const id = useId();

  useEffect(() => {
    if (!number) {
      ref.current?.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor, getIn(formik.values, name)]);

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (trim) {
      e.target.value = e.target.value.trim();
    }
    formik.handleBlur(e);
    if (onBlur) {
      onBlur(e.target.value);
    }
  };

  const handleChange = async (value: string) => {
    let finalValue: string | undefined = value;

    if (!allowLowercase) {
      finalValue = finalValue.toUpperCase();
    }

    if (number && finalValue.trim() === "") {
      finalValue = undefined;
    }

    if (finalValue === "" && useUndefinedForEmpty) {
      finalValue = undefined;
    }

    if (valueProcessor) {
      finalValue = valueProcessor(finalValue);
    }

    setCursor(ref.current.selectionStart);
    formik.setFieldValue(name, finalValue);
  };

  return (
    <Form.Group>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      {tableCell ? (
        <S.FormControlTableCell
          autoComplete="off"
          disabled={disabled}
          id={id}
          isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
          name={name}
          onBlur={(e: FocusEvent<HTMLInputElement>) => handleBlur(e)}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          ref={ref}
          type={number ? "number" : "text"}
          value={getIn(formik.values, name) ?? ""}
        />
      ) : (
        <Form.Control
          autoComplete="off"
          disabled={disabled}
          id={id}
          isInvalid={getIn(formik.errors, name) && getIn(formik.touched, name)}
          name={name}
          onBlur={(e) => handleBlur(e as ChangeEvent<HTMLInputElement>)}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          ref={ref}
          style={style}
          type={number ? "number" : "text"}
          value={getIn(formik.values, name) ?? ""}
        />
      )}
      <Form.Control.Feedback type="invalid">{getIn(formik.errors, name)}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default Input;
