import React from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { CommaListInput } from "src/components/ui";
import { pluralize } from "src/utils";

interface BatchEditTagsModalProps {
  show: boolean;
  videoMapCount: number;
  onClose: (addTags: string[], removeTags: string[]) => void;
}

function BatchEditTagsModal({ show, videoMapCount, onClose }: Readonly<BatchEditTagsModalProps>) {
  let addTags: string[] = [];
  let removeTags: string[] = [];
  return (
    <Modal show={show} onHide={() => onClose([], [])} backdrop="static" size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Batch Edit Tags</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            Editing tags for {videoMapCount} {pluralize("video map", videoMapCount)}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Add tags</Form.Label>
              <CommaListInput
                placeholder="STARS, EOVM, ..."
                onChange={(v) => {
                  addTags = v;
                }}
                allowSpaces
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form.Group>
              <Form.Label>Remove tags</Form.Label>
              <CommaListInput
                placeholder="STARS, EOVM, ..."
                onChange={(v) => {
                  removeTags = v;
                }}
                allowSpaces
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button type="button" className="btn btn-secondary" onClick={() => onClose([], [])}>
          Close
        </button>
        <button className="btn btn-success" type="button" onClick={() => onClose(addTags, removeTags)}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default BatchEditTagsModal;
