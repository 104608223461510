import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { CommaListInput, Input } from "src/components/ui";

interface ScratchpadModalProps {
  index: number | undefined;
  isPrimary: boolean;
  show: boolean;
  onClose: () => void;
  formik: FormikProps<Facility>;
}

function ScratchpadModal({ index, isPrimary, show, onClose, formik }: Readonly<ScratchpadModalProps>) {
  if (index === undefined) {
    return undefined;
  }

  const scratchpadRulesString = `starsConfiguration.${isPrimary ? "primary" : "secondary"}ScratchpadRules`;

  return (
    <Modal show={show} onHide={onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>{`${isPrimary ? "Primary" : "Secondary"} Scratchpad Rule`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col>
            <CommaListInput
              name={`${scratchpadRulesString}[${index}].airportIds`}
              formik={formik}
              placeholder="BOS, BED..."
              label="Airport IDs"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input
              name={`${scratchpadRulesString}[${index}].template`}
              formik={formik}
              placeholder="P###"
              label="Template"
            />
          </Col>
          <Col>
            <Input
              name={`${scratchpadRulesString}[${index}].searchPattern`}
              formik={formik}
              placeholder="LBSTA#"
              label="Search Pattern"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Input
              number
              name={`${scratchpadRulesString}[${index}].minAltitude`}
              formik={formik}
              placeholder="0"
              label="Min. Altitude"
            />
          </Col>
          <Col>
            <Input
              number
              name={`${scratchpadRulesString}[${index}].maxAltitude`}
              formik={formik}
              placeholder="0"
              label="Max. Altitude"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ScratchpadModal;
