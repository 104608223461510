import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Facility, StarsArea } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import {
  DeleteIconButton,
  EditIconButton,
  ErrorTableCell,
  IconButton,
  Table,
  TableHeader,
  TableNoRows,
} from "src/components/ui";
import { DeleteModal, DeleteModalSpec, ModalSpec, StarsAreaModal } from "src/components/ui/modal";
import { artccSelector, useAppSelector } from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface AreasProps {
  formik: FormikProps<Facility>;
  areas: StarsArea[];
}

function Areas({ formik, areas }: Readonly<AreasProps>) {
  const artcc = useAppSelector(artccSelector);
  const tableRef = useRef<HTMLDivElement>(undefined!);
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec>({ show: false });

  const handleAddArea = () => {
    const index = addToFormikArray(formik, "starsConfiguration.areas", new StarsArea(formik.values.type), tableRef);
    setModalSpec({ show: true, index });
  };

  const handleCopyArea = (area: StarsArea) => {
    const index = addToFormikArray(formik, "starsConfiguration.areas", area.copy());
    setModalSpec({ show: true, index });
  };

  const handleDeleteArea = (area: StarsArea) => {
    const canDelete = area.canDelete(artcc);
    if (canDelete.canDelete) {
      deleteFromFormikArray(formik, "starsConfiguration.areas", area);
    } else {
      setDeleteModalSpec({ show: true, canDeleteSpec: canDelete, itemName: area.name });
    }
  };

  return (
    <>
      <Row>
        <TableHeader label="Area Configurations" addButtonLabel="Add Area" onAdd={handleAddArea} />
      </Row>
      <Row className="mt-2">
        <Col md>
          <Table ref={tableRef} maxHeight={500}>
            <thead>
              <tr>
                <th>Name</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {areas.map((area, i) => (
                <tr key={area.id}>
                  <td>
                    <ErrorTableCell
                      formik={formik}
                      displayValue={area.name}
                      name={`starsConfiguration.areas[${i}]`}
                      errorMessage="Invalid Area Configuration"
                    />
                  </td>
                  <td>
                    <ButtonGroup>
                      <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                      <IconButton type="secondary" onClick={() => handleCopyArea(area)} icon={faCopy} />
                      <DeleteIconButton onClick={() => handleDeleteArea(area)} />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
            <TableNoRows rows={areas} text="No Area Configurations defined" />
          </Table>
        </Col>
      </Row>
      <StarsAreaModal
        show={modalSpec.show}
        index={modalSpec.index}
        formik={formik}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
      <DeleteModal
        show={deleteModalSpec.show}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
        itemName={deleteModalSpec.itemName}
      />
    </>
  );
}

export default Areas;
