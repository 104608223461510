import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Scenario, ScenarioAircraft } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { DeleteIconButton, IconButton, OrderButtonPair, Table } from "src/components/ui";
import * as S from "src/styles/ui";

interface TrainingScenarioFlightStripConfigurationModalProps {
  show: boolean;
  index: number | undefined;
  formik: FormikProps<Scenario>;
  onClose: () => void;
}

function TrainingScenarioFlightStripConfigurationModal({
  show,
  index,
  formik,
  onClose,
}: Readonly<TrainingScenarioFlightStripConfigurationModalProps>) {
  const [availableAircraft, setAvailableAircraft] = useState<ScenarioAircraft[]>([]);

  useEffect(() => {
    if (index !== undefined) {
      setAvailableAircraft(
        [...formik.values.aircraft].filter(
          (a) =>
            !formik.values.flightStripConfigurations[index].aircraftIds.includes(a.id) &&
            !formik.values.flightStripConfigurations.some((c) => c.aircraftIds.includes(a.id)),
        ),
      );
    }
  }, [index, show]);

  if (index === undefined) {
    return undefined;
  }

  const configuration = formik.values.flightStripConfigurations[index];

  if (!configuration) {
    return undefined;
  }

  const handleSelectAircraft = (aircraft: ScenarioAircraft, selected: boolean) => {
    const aircraftList = [...configuration.aircraftIds];
    if (selected) {
      aircraftList.push(aircraft.id);
    } else {
      aircraftList.splice(aircraftList.indexOf(aircraft.id), 1);
    }

    formik.setFieldValue(`flightStripConfigurations[${index}].aircraftIds`, aircraftList);
    setAvailableAircraft(
      [...formik.values.aircraft].filter(
        (a) =>
          !aircraftList.includes(a.id) &&
          !formik.values.flightStripConfigurations.some(
            (c) => c.id !== configuration.id && c.aircraftIds.includes(a.id),
          ),
      ),
    );
  };

  return (
    <Modal show={show} onHide={() => onClose()} size="lg" backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Flight Strip Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col md className="mb-3">
            <Form.Group>
              <Form.Label>All Aircraft</Form.Label>
              <S.ScrollContainer>
                {!availableAircraft.length ? (
                  <i>No aircraft available to select</i>
                ) : (
                  <Table maxHeight={500}>
                    <tbody>
                      {availableAircraft.map((a) => (
                        <tr key={a.id}>
                          <td>
                            {a.aircraftId} ({a.aircraftType}){" "}
                            {a.spawnDelay && a.spawnDelay > 0 ? `+${a.spawnDelay}` : ""}
                          </td>
                          <td>
                            <span className="float-right">
                              <IconButton icon={faPlus} type="success" onClick={() => handleSelectAircraft(a, true)} />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </S.ScrollContainer>
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group>
              <Form.Label>Flight Strip Rack</Form.Label>
              <S.ScrollContainer>
                {!configuration.aircraftIds.length ? (
                  <i>No aircraft selected</i>
                ) : (
                  <Table maxHeight={500}>
                    <tbody>
                      {configuration.aircraftIds
                        .filter((a) => formik.values.aircraft.some((o) => o.id === a))
                        .map((a) => formik.values.aircraft.find((o) => o.id === a)!)
                        .map((a, i) => (
                          <tr key={a.id}>
                            <td>
                              {a.aircraftId} ({a.aircraftType}){" "}
                              {a.spawnDelay && a.spawnDelay > 0 ? `+${a.spawnDelay}` : ""}
                            </td>
                            <td>
                              <div className="btn-group float-right">
                                <OrderButtonPair
                                  formik={formik}
                                  name={`flightStripConfigurations[${index}].aircraftIds`}
                                  index={i}
                                />
                                <DeleteIconButton onClick={() => handleSelectAircraft(a, false)} />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}
              </S.ScrollContainer>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingScenarioFlightStripConfigurationModal;
