import { getAllFacilityIds } from "@vatsim-vnas/js-libs/api/data";
import { Facility, FacilityType, facilityTypeToString } from "@vatsim-vnas/js-libs/models/facilities";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Input, SelectInput } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import * as yup from "yup";

interface AddFacilityForm {
  facility: Facility;
  parentFacilityId: string;
}

interface AddFacilityModalProps {
  show: boolean;
  onClose: (newFacility?: Facility, parentFacilityId?: string) => void;
}

function AddFacilityModal({ show, onClose }: Readonly<AddFacilityModalProps>) {
  const artcc = useAppSelector(artccSelector);
  const parentFacilityOptions = useMemo(() => artcc.getAllFacilities().filter((f) => f.type !== FacilityType.Atct), []);
  const newFacility = new Facility();
  const validationSchema = useRef<yup.AnyObjectSchema>();

  useEffect(() => {
    (async () => {
      const res = await getAllFacilityIds();
      if (!res.ok) {
        throw new Error(`Failed to get facility IDs: ${res.statusText}`);
      }
      validationSchema.current = yup.object().shape({
        facility: newFacility.createSchema(artcc, res.data),
        parentFacilityId: yup.string().required().label("Parent Facility"),
      });
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      facility: newFacility,
      parentFacilityId: artcc.id,
    } as AddFacilityForm,
    validationSchema: validationSchema.current,
    onSubmit: (values) => onClose(values.facility, values.parentFacilityId),
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Facility</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <Row>
            <Col>
              <Input formik={formik} name="facility.id" label="ID" placeholder="A90" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Input formik={formik} name="facility.name" label="Name" placeholder="Boston TRACON" allowLowercase />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <SelectInput
                formik={formik}
                name="facility.type"
                label="Type"
                options={getEnumOptions(FacilityType, facilityTypeToString, (v) => v !== FacilityType.Artcc)}
                valueProcessor={(value) => {
                  if (value !== "ATCT") {
                    formik.setFieldValue("parentFacilityId", artcc.id);
                  }
                  return value;
                }}
              />
            </Col>
          </Row>
          {formik.values.facility.type === FacilityType.Atct && (
            <Row className="mt-3">
              <Col>
                <SelectInput
                  formik={formik}
                  name="parentFacilityId"
                  label="Parent Facility"
                  options={
                    <>
                      {parentFacilityOptions.map((f) => (
                        <option value={f.id} key={f.id}>
                          {f.id}
                        </option>
                      ))}
                    </>
                  }
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button className="btn btn-success" type="submit" disabled={!formik.dirty || !formik.isValid}>
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddFacilityModal;
