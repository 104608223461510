import { FormikProps, getIn } from "formik";
import React from "react";
import { reorderFormikArray } from "src/utils";
import { OrderIconButton } from "./IconButton";

interface OrderButtonPairProps<F> {
  formik: FormikProps<F>;
  index: number;
  name: string;
}

function OrderButtonPair<F>({ formik, index, name }: Readonly<OrderButtonPairProps<F>>) {
  return (
    <>
      <OrderIconButton onClick={() => reorderFormikArray(formik, name, index, true)} up disabled={index === 0} />
      <OrderIconButton
        onClick={() => reorderFormikArray(formik, name, index, false)}
        disabled={index === getIn(formik.values, name).length - 1}
      />
    </>
  );
}

export default OrderButtonPair;
