import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Artcc } from "@vatsim-vnas/js-libs/models/facilities";
import { Scenario, ScenarioAircraftGenerator } from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, EditIconButton, ErrorTableCell, Table, TableNoRows } from "src/components/ui";
import { ModalSpec, TrainingScenarioArrivalGeneratorModal } from "src/components/ui/modal";
import { addToFormikArray, deleteFromFormikArray } from "src/utils/helpers";

const getMaxString = (generator: ScenarioAircraftGenerator) => {
  if (generator.isTimeBased()) {
    return generator.maxTime ? `${generator.maxTime} s` : "";
  }
  return generator.maxDistance ? `${generator.maxDistance} nm` : "";
};

const getIntervalString = (generator: ScenarioAircraftGenerator) => {
  if (generator.isTimeBased()) {
    return generator.intervalTime ? `${generator.intervalTime} s` : "";
  }
  return generator.intervalDistance ? `${generator.intervalDistance} nm` : "";
};

interface ArrivalGeneratorConfigurationProps {
  formik: FormikProps<Scenario>;
  artccs: Artcc[];
}

function ArrivalGeneratorConfiguration({ formik, artccs }: Readonly<ArrivalGeneratorConfigurationProps>) {
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });
  const generators = formik.values.aircraftGenerators;

  return (
    <Card>
      <Card.Header>
        <Card.Title>Arrival Generator</Card.Title>
        <div className="card-tools">
          <AddIconButton
            text="Add Arrival Generator"
            onClick={() => {
              const index = addToFormikArray(formik, "aircraftGenerators", new ScenarioAircraftGenerator());
              setModalSpec({ show: true, index });
            }}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form.Group>
              <Table>
                <thead>
                  <tr>
                    <th>Runway</th>
                    <th>Initial Distance</th>
                    <th>Max</th>
                    <th>Interval</th>
                    <th>Engine Type</th>
                    <th>Weight Category</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {generators.map((generator, i) => (
                    <tr key={generator.id}>
                      <td>
                        <ErrorTableCell
                          formik={formik}
                          errorMessage="Invalid Arrival Generator"
                          displayValue={generator.runway}
                          name={`aircraftGenerators[${i}]`}
                        />
                      </td>
                      <td>{generator.initialDistance ? `${generator.initialDistance} nm` : ""}</td>
                      <td>{getMaxString(generator)}</td>
                      <td>
                        {getIntervalString(generator)}
                        {generator.randomizeInterval && <FontAwesomeIcon icon={faRandom} className="ml-1" />}
                      </td>
                      <td>{generator.engineType}</td>
                      <td>
                        {generator.weightCategory}
                        {generator.randomizeWeightCategory && <FontAwesomeIcon icon={faRandom} className="ml-1" />}
                      </td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <DeleteIconButton
                            onClick={() => deleteFromFormikArray(formik, "aircraftGenerators", generator)}
                          />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                  <TableNoRows rows={generators} text="No Arrival Generators defined" />
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingScenarioArrivalGeneratorModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        artccs={artccs}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default ArrivalGeneratorConfiguration;
