import { FormikProps, getIn } from "formik";
import React from "react";
import BaseSwitch from "../BaseSwitch";

interface SwitchProps<F> {
  checkedValue?: unknown;
  disabled?: boolean;
  formik: FormikProps<F>;
  label?: string;
  name: string;
  onChange?: (checked: boolean) => void;
}

function Switch<F>({
  checkedValue = undefined,
  disabled = false,
  formik,
  label = undefined,
  name,
  onChange = undefined,
}: Readonly<SwitchProps<F>>) {
  const getCheckedValue = () => {
    if (checkedValue instanceof Function) {
      return checkedValue();
    }

    return checkedValue ?? true;
  };

  const handleChange = (newChecked: boolean) => {
    if (newChecked) {
      formik.setFieldValue(name, getCheckedValue());
    } else {
      formik.setFieldValue(name, checkedValue ? undefined : false);
    }
    onChange?.(newChecked);
  };

  return (
    <BaseSwitch
      checked={!!getIn(formik.values, name)}
      disabled={disabled}
      label={label}
      onChange={(e) => handleChange(e.target.checked)}
    />
  );
}

export default Switch;
