import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Artcc, Position } from "@vatsim-vnas/js-libs/models/facilities";
import {
  Scenario,
  ScenarioAircraftAutoTrackConfiguration,
  ScenarioAircraftGeneratorEngineType,
  ScenarioAircraftGeneratorWeightCategory,
} from "@vatsim-vnas/js-libs/models/training";
import { getEnumOptions } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { AddIconButton, Input, SelectInput, Switch } from "src/components/ui";

interface TrainingScenarioArrivalGeneratorModalProps {
  formik: FormikProps<Scenario>;
  index: number | undefined;
  artccs: Artcc[];
  onClose: () => void;
  show: boolean;
}

function TrainingScenarioArrivalGeneratorModal({
  show,
  index,
  formik,
  artccs,
  onClose,
}: Readonly<TrainingScenarioArrivalGeneratorModalProps>) {
  const [isTimeBased, setIsTimeBased] = useState(false);

  useEffect(() => {
    if (index !== undefined) {
      setIsTimeBased(formik.values.aircraftGenerators[index].isTimeBased());
    }
  }, [index, show]);

  const allPositions = useMemo(() => {
    const positions: Position[] = [];

    formik.values.atc.forEach((atc) => {
      if (atc.facilityId && atc.positionId) {
        const artcc = artccs.find((a) => a.id === atc.artccId)!;
        const facility = artcc.getFacility(atc.facilityId);
        const position = facility.positions?.find((p) => p.id === atc.positionId);
        if (position) {
          positions.push(position);
        }
      }
    });
    return positions;
  }, [formik.values.atc]);

  if (index === undefined) {
    return undefined;
  }

  const generator = formik.values.aircraftGenerators[index];

  if (!generator) {
    return undefined;
  }

  const handleChangeUnits = (timeBased: boolean) => {
    if (timeBased) {
      formik.setFieldValue(`aircraftGenerators[${index}].maxTime`, generator.maxDistance);
      formik.setFieldValue(`aircraftGenerators[${index}].intervalTime`, generator.intervalDistance);
      formik.setFieldValue(`aircraftGenerators[${index}].startTimeOffset`, generator.startTimeOffset);
      formik.setFieldValue(`aircraftGenerators[${index}].maxDistance`, undefined);
      formik.setFieldValue(`aircraftGenerators[${index}].intervalDistance`, undefined);
    } else {
      formik.setFieldValue(`aircraftGenerators[${index}].maxDistance`, generator.maxTime);
      formik.setFieldValue(`aircraftGenerators[${index}].intervalDistance`, generator.intervalTime);
      formik.setFieldValue(`aircraftGenerators[${index}].maxTime`, undefined);
      formik.setFieldValue(`aircraftGenerators[${index}].intervalTime`, undefined);
      formik.setFieldValue(`aircraftGenerators[${index}].startTimeOffset`, undefined);
    }
    setIsTimeBased(timeBased);
  };

  return (
    <Modal show={show} onHide={() => onClose()} backdrop="static" size="lg">
      <Modal.Header className="dark-mode">
        <Modal.Title>Edit Arrival Generator</Modal.Title>
      </Modal.Header>
      <Modal.Body className="dark-mode">
        <Row>
          <Col lg={6}>
            <Input formik={formik} name={`aircraftGenerators[${index}].runway`} label="Runway" placeholder="4R" />
          </Col>
          <Col lg={6}>
            <Input
              number
              formik={formik}
              name={`aircraftGenerators[${index}].initialDistance`}
              label="Initial Distance (nm)"
              placeholder="10"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6}>
            <SelectInput
              formik={formik}
              label="Engine Type"
              name={`aircraftGenerators[${index}].engineType`}
              options={getEnumOptions(ScenarioAircraftGeneratorEngineType)}
            />
          </Col>
          <Col lg={6}>
            {isTimeBased ? (
              <Input
                number
                formik={formik}
                name={`aircraftGenerators[${index}].maxTime`}
                label="Max Time (s)"
                placeholder="600"
              />
            ) : (
              <Input
                number
                formik={formik}
                name={`aircraftGenerators[${index}].maxDistance`}
                label="Max Distance (nm)"
                placeholder="50"
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6}>
            <SelectInput
              formik={formik}
              label="Weight Category"
              name={`aircraftGenerators[${index}].weightCategory`}
              options={getEnumOptions(ScenarioAircraftGeneratorWeightCategory)}
            />
          </Col>
          <Col lg={6}>
            {isTimeBased ? (
              <Input
                number
                formik={formik}
                name={`aircraftGenerators[${index}].intervalTime`}
                label="Interval Time (s)"
                placeholder="90"
              />
            ) : (
              <Input
                number
                formik={formik}
                name={`aircraftGenerators[${index}].intervalDistance`}
                label="Interval Distance (nm)"
                placeholder="5"
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6}>
            <Switch
              formik={formik}
              label="Randomize Weight Category"
              name={`aircraftGenerators[${index}].randomizeWeightCategory`}
            />
          </Col>
          <Col lg={6}>
            <Switch
              formik={formik}
              label="Randomize Interval"
              name={`aircraftGenerators[${index}].randomizeInterval`}
            />
          </Col>
        </Row>
        {isTimeBased && (
          <Row className="mt-3">
            <Col lg={6}>
              <Input
                number
                formik={formik}
                name={`aircraftGenerators[${index}].startTimeOffset`}
                label="Start Time Offset (s)"
                placeholder="0"
              />
            </Col>
          </Row>
        )}
        <Row className="mt-3" />
        <Row className="mt-3">
          <Col lg={6}>
            <Form.Label>Units</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Check
              inline
              label="Distance (nm)"
              type="radio"
              name="units"
              defaultChecked={!generator.isTimeBased()}
              onChange={(e) => handleChangeUnits(!e.target.checked)}
            />
            <Form.Check
              inline
              label="Time (s)"
              type="radio"
              name="units"
              defaultChecked={generator.isTimeBased()}
              onChange={(e) => handleChangeUnits(e.target.checked)}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Auto Track Configuration</h5>
          </Col>
          <Col>
            {!generator.autoTrackConfiguration ? (
              <div className="float-right m-2">
                <AddIconButton
                  text="Create Auto Track Configuration"
                  onClick={() => {
                    formik.setFieldValue(
                      `aircraftGenerators[${index}].autoTrackConfiguration`,
                      new ScenarioAircraftAutoTrackConfiguration(),
                    );
                  }}
                />
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-danger float-right m-2"
                onClick={() => formik.setFieldValue(`aircraftGenerators[${index}].autoTrackConfiguration`, undefined)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                Delete Auto Track Configuration
              </button>
            )}
          </Col>
        </Row>
        {generator.autoTrackConfiguration ? (
          <>
            <Row>
              <Col lg={4} className="mb-3">
                <SelectInput
                  formik={formik}
                  name={`aircraftGenerators[${index}].autoTrackConfiguration.positionId`}
                  label="Position"
                  options={allPositions.map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                />
              </Col>
              <Col lg={4} className="mb-3">
                <Input
                  number
                  formik={formik}
                  name={`aircraftGenerators[${index}].autoTrackConfiguration.handoffDelay`}
                  label="Handoff Delay (s)"
                  placeholder="60"
                />
              </Col>
              <Col lg={4} className="mb-3">
                <Input
                  formik={formik}
                  name={`aircraftGenerators[${index}].autoTrackConfiguration.scratchPad`}
                  label="ScratchPad"
                  placeholder="I2L"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="mb-3">
                <Input
                  formik={formik}
                  name={`aircraftGenerators[${index}].autoTrackConfiguration.interimAltitude`}
                  label="Temp Altitude"
                  placeholder="110"
                />
              </Col>
              <Col lg={4}>
                <Input
                  formik={formik}
                  name={`aircraftGenerators[${index}].autoTrackConfiguration.clearedAltitude`}
                  label="Cleared Altitude"
                  placeholder="240"
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <i>No Auto Track Configuration</i>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer className="dark-mode">
        <button className="btn btn-primary" type="button" onClick={onClose}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default TrainingScenarioArrivalGeneratorModal;
