import { faLink } from "@fortawesome/free-solid-svg-icons";
import { TrainingAirport } from "@vatsim-vnas/js-libs/models/training";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { IconButton, SelectInput, UploadFileButton } from "src/components/ui";
import { artccSelector, trainingAirportSummariesSelector, useAppSelector } from "src/redux";

interface AddTrainingAirportModalProps {
  show: boolean;
  onClose: (newAirport?: TrainingAirport, file?: File) => void;
}

const CONVERTER_URL = "https://atctrainer.collinkoldoff.dev/converter";

function AddTrainingAirportModal({ show, onClose }: Readonly<AddTrainingAirportModalProps>) {
  const artcc = useAppSelector(artccSelector);
  const airports = useAppSelector(trainingAirportSummariesSelector);
  const facilityIds = useMemo(
    () =>
      artcc
        .getAllFacilities()
        .filter((f) => f.isCabFacility() && !airports.some((a) => f.id === a.id))
        .map((f) => f.id)
        .sort((a, b) => a.localeCompare(b)),
    [],
  );
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const formik = useFormik({
    initialValues: { ...new TrainingAirport(artcc.id), id: undefined! },
    validationSchema: TrainingAirport.schema,
    onSubmit: (values) => onClose(values, selectedFile),
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={() => onClose} backdrop="static">
      <Modal.Header className="dark-mode">
        <Modal.Title>Add Airport</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body className="dark-mode">
          <SelectInput
            formik={formik}
            name="id"
            label="Airport"
            options={facilityIds.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          />
          <Row className="mt-3">
            <Col>
              <Form.Group>
                <UploadFileButton
                  label="Select airport geojson"
                  onSelect={(f) => setSelectedFile(f as File)}
                  accept=".geojson"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <IconButton
                icon={faLink}
                onClick={() => window.open(CONVERTER_URL)}
                text="Convert Legacy Files"
                type="secondary"
                className="w-100"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="dark-mode">
          <button type="button" className="btn btn-secondary" onClick={() => onClose()}>
            Close
          </button>
          <button
            className="btn btn-success"
            type="submit"
            disabled={!formik.dirty || !formik.isValid || selectedFile === undefined}
          >
            Add
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddTrainingAirportModal;
