import { Facility, ScratchpadRule } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import {
  DeleteIconButton,
  EditIconButton,
  ErrorTableCell,
  OrderButtonPair,
  Table,
  TableHeader,
  TableNoRows,
} from "src/components/ui";
import { ModalSpec, ScratchpadModal } from "src/components/ui/modal";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface ScratchpadRulesProps {
  formik: FormikProps<Facility>;
  isPrimary?: boolean;
  scratchpadRules: ScratchpadRule[];
}

function ScratchpadRules({ formik, isPrimary = false, scratchpadRules }: Readonly<ScratchpadRulesProps>) {
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  const scratchpadRulesString = `starsConfiguration.${isPrimary ? "primary" : "secondary"}ScratchpadRules`;

  return (
    <>
      <Row className={isPrimary ? "" : "mt-3"}>
        <TableHeader
          label={`${isPrimary ? "Primary" : "Secondary"} Scratchpad Rules`}
          addButtonLabel="Add Rule"
          onAdd={() => {
            const index = addToFormikArray(formik, scratchpadRulesString, new ScratchpadRule(), undefined, true);
            setModalSpec({ show: true, index });
          }}
        />
      </Row>
      <Row className="mt-2">
        <Col>
          <Table maxHeight={500}>
            <thead>
              <tr>
                <th>Template</th>
                <th>Search Pattern</th>
                <th>Airport IDs</th>
                <th className="w-0">Actions</th>
              </tr>
            </thead>
            <tbody>
              {scratchpadRules.map((scratchpadRule, i) => (
                <tr key={scratchpadRule.id}>
                  <td>
                    <ErrorTableCell
                      formik={formik}
                      displayValue={scratchpadRule.template}
                      name={`${scratchpadRulesString}[${scratchpadRules.indexOf(scratchpadRule)}]`}
                      errorMessage="Invalid Scratchpad Rule"
                    />
                  </td>
                  <td>{scratchpadRule.searchPattern}</td>
                  <td>{scratchpadRule.airportIds.join(", ")}</td>
                  <td>
                    <ButtonGroup>
                      <OrderButtonPair formik={formik} index={i} name={scratchpadRulesString} />
                      <EditIconButton
                        onClick={() => setModalSpec({ show: true, index: scratchpadRules.indexOf(scratchpadRule) })}
                      />
                      <DeleteIconButton
                        onClick={() => deleteFromFormikArray(formik, scratchpadRulesString, scratchpadRule)}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
              <TableNoRows
                rows={scratchpadRules}
                text={`No ${isPrimary ? "Primary" : "Secondary"} Scratchpad Rules defined`}
              />
            </tbody>
          </Table>
        </Col>
      </Row>
      <ScratchpadModal
        show={modalSpec.show}
        formik={formik}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
        isPrimary={isPrimary}
      />
    </>
  );
}

export default ScratchpadRules;
