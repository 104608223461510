import {
  Facility,
  StarsConfiguration,
  TowerCabConfiguration,
  facilityTypeToString,
} from "@vatsim-vnas/js-libs/models/facilities";
import React, { useMemo, useState } from "react";
import { ButtonGroup, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AddIconButton, DeleteIconButton, EditIconButton, Table } from "src/components/ui";
import { AddFacilityModal, DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import { addFacility, artccSelector, deleteFacility, saveArtcc, useAppDispatch, useAppSelector } from "src/redux";

function Facilities() {
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<Facility>>({ show: false });

  const visibleFacilities = useMemo(
    () =>
      artcc
        .getAllFacilities()
        .filter(
          (f) =>
            f.id.toLowerCase().includes(search.toLowerCase()) || f.name.toLowerCase().includes(search.toLowerCase()),
        ),
    [artcc, search],
  );

  const handleAdd = (newFacility?: Facility, parentFacilityId?: string) => {
    if (newFacility && parentFacilityId) {
      if (newFacility.isStarsFacility()) {
        newFacility.starsConfiguration = new StarsConfiguration();
      }
      if (newFacility.isCabFacility()) {
        newFacility.towerCabConfiguration = new TowerCabConfiguration();
      }
      dispatch(
        addFacility({
          facility: newFacility,
          parentFacilityId,
        }),
      );
      dispatch(saveArtcc("Successfully added facility"));
      navigate(newFacility.id);
    }
    setShowAddModal(false);
  };

  const handleDelete = (confirm: boolean) => {
    if (confirm) {
      dispatch(deleteFacility(deleteModalSpec.item!));
      dispatch(saveArtcc("Successfully deleted facility"));
    }
    setDeleteModalSpec((p) => ({ ...p, show: false }));
  };

  return (
    <>
      <h1 className="content-header">Facilities</h1>
      <section className="content">
        <Container fluid>
          <Card>
            <Card.Header>
              <Card.Title>{artcc.id} Facilities</Card.Title>
              <div className="card-tools d-flex gap-3">
                <input
                  className="form-control w-auto"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                />
                <AddIconButton text="Add Facility" onClick={() => setShowAddModal(true)} />
              </div>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {visibleFacilities.map((facility) => (
                    <tr key={facility.id}>
                      <td>{facility.id}</td>
                      <td>{facility.name}</td>
                      <td>{facilityTypeToString(facility.type)}</td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => navigate(facility.id)} />
                          <DeleteIconButton
                            onClick={() =>
                              setDeleteModalSpec({
                                show: true,
                                item: facility,
                                itemName: facility.id,
                                canDeleteSpec: facility.canDelete(artcc),
                              })
                            }
                          />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer />
          </Card>
        </Container>
      </section>
      <AddFacilityModal show={showAddModal} onClose={handleAdd} />
      <DeleteModal
        show={deleteModalSpec.show}
        itemName={deleteModalSpec.itemName}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={handleDelete}
      />
    </>
  );
}

export default Facilities;
