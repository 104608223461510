import {
  TrainingAirport as TrainingAirportModel,
  TrainingAirportVectorDeparture,
} from "@vatsim-vnas/js-libs/models/training";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, EditIconButton, ErrorTableCell, Table } from "src/components/ui";
import { ModalSpec, TrainingAirportRadarVectorDepartureModal } from "src/components/ui/modal";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

interface RadarVectorDepartureConfigurationProps {
  formik: FormikProps<TrainingAirportModel>;
}

function RadarVectorDepartureConfiguration({ formik }: Readonly<RadarVectorDepartureConfigurationProps>) {
  const [modalSpec, setModalSpec] = useState<ModalSpec>({ show: false });

  const handleAdd = () => {
    const index = addToFormikArray(formik, "vectorDepartures", new TrainingAirportVectorDeparture());
    setModalSpec({ show: true, index });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Radar Vector SIDs</Card.Title>
        <div className="card-tools">
          <AddIconButton text="Add SID" onClick={handleAdd} />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg>
            <Form.Group>
              <Form.Label>Radar Vector SIDs</Form.Label>
              <Table>
                <thead>
                  <tr>
                    <th>SID</th>
                    <th>Runway</th>
                    <th>Heading</th>
                    <th>DME Distance</th>
                    <th>DME Source</th>
                    <th className="w-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.vectorDepartures.map((sid, i) => (
                    <tr key={sid.id}>
                      <td>
                        <ErrorTableCell
                          formik={formik}
                          errorMessage="Invalid SID"
                          displayValue={sid.sid}
                          name={`vectorDepartures[${i}]`}
                        />
                      </td>
                      <td>{sid.runway}</td>
                      <td>{sid.heading}</td>
                      <td>{sid.dmeDistance}</td>
                      <td>{sid.dmeSource}</td>
                      <td>
                        <ButtonGroup>
                          <EditIconButton onClick={() => setModalSpec({ show: true, index: i })} />
                          <DeleteIconButton onClick={() => deleteFromFormikArray(formik, "vectorDepartures", sid)} />
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
      <TrainingAirportRadarVectorDepartureModal
        formik={formik}
        show={modalSpec.show}
        index={modalSpec.index}
        onClose={() => setModalSpec((p) => ({ ...p, show: false }))}
      />
    </Card>
  );
}

export default RadarVectorDepartureConfiguration;
