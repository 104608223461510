import { faFileUpload, faSync } from "@fortawesome/free-solid-svg-icons";
import React, { useImperativeHandle, useRef, useState } from "react";
import { FileUpload } from "src/utils";
import IconButton from "./IconButton";

interface UploadFileButtonProps {
  accept: string;
  label: string;
  fileName?: string;
  onSelect: (file: FileUpload) => void;
  multiple?: boolean;
  disabled?: boolean;
  processing?: boolean;
  showFileName?: boolean;
}

export interface UploadFileButtonHandle {
  clear: () => void;
}

const UploadFileButton = React.forwardRef<UploadFileButtonHandle, UploadFileButtonProps>(
  (
    {
      accept,
      label,
      fileName = undefined,
      onSelect,
      multiple = false,
      disabled = undefined,
      processing = false,
      showFileName = true,
    },
    ref,
  ) => {
    const [selectedFileName, setSelectedFileName] = useState("");
    const inputRef = useRef<HTMLInputElement>(undefined!);

    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRef.current.value = "";
      },
    }));

    const handleChange = (files: FileList | null) => {
      if (files?.length) {
        setSelectedFileName(files.length > 1 ? `${files.length} files` : files[0].name);
        onSelect(multiple ? files : files[0]);
      } else {
        setSelectedFileName("");
        onSelect(undefined);
      }
    };

    return (
      <>
        <input
          type="file"
          multiple={multiple}
          hidden
          ref={inputRef}
          accept={accept}
          onChange={(e) => handleChange(e.target.files)}
        />
        <IconButton
          type="success"
          className="btn-block"
          icon={processing ? faSync : faFileUpload}
          text={processing ? undefined : label}
          disabled={disabled ?? processing}
          onClick={() => inputRef.current.click()}
          spin={processing}
        />
        {showFileName && <small>{fileName ?? selectedFileName}</small>}
      </>
    );
  },
);

UploadFileButton.displayName = "UploadFileButton";

export default UploadFileButton;
