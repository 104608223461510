import React, { useId } from "react";

interface BaseSwitchProps {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function BaseSwitch({
  checked = undefined,
  disabled = undefined,
  label = undefined,
  onChange = undefined,
}: Readonly<BaseSwitchProps>) {
  const id = useId();

  return (
    <div className="custom-control custom-switch">
      <input
        checked={checked}
        className="custom-control-input"
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default BaseSwitch;
