import { faLinux, faWindows } from "@fortawesome/free-brands-svg-icons";
import { getTowerCabImageUpdatedAtAsync, uploadTowerCabImageAsync } from "@vatsim-vnas/js-libs/api/data";
import { ImageResolution } from "@vatsim-vnas/js-libs/models/data-api";
import { Facility } from "@vatsim-vnas/js-libs/models/facilities";
import { GeoPoint } from "@vatsim-vnas/js-libs/models/vnas/common";
import { dateToZuluString } from "@vatsim-vnas/js-libs/utils";
import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { IconButton, Input, UploadFileButton, UploadFileButtonHandle, VideoMapInput } from "src/components/ui";
import { artccSelector, useAppSelector } from "src/redux";
import * as S from "src/styles/ui";
import { DOMAIN, FileUpload } from "src/utils";

interface TowerCabConfigurationProps {
  formik: FormikProps<Facility>;
}

function TowerCabConfiguration({ formik }: Readonly<TowerCabConfigurationProps>) {
  const artccId = useAppSelector(artccSelector).id;

  const [highResUpdatedAt, setHighResUpdatedAt] = useState("...");
  const [lowResUpdatedAt, setLowResUpdatedAt] = useState("...");
  const [processingHighRes, setProcessingHighRes] = useState(false);
  const [processingLowRes, setProcessingLowRes] = useState(false);

  const lowResInputRef = useRef<UploadFileButtonHandle>(undefined!);
  const highResInputRef = useRef<UploadFileButtonHandle>(undefined!);

  const towerCabConfiguration = formik.values.towerCabConfiguration!;

  const getImageUpdatedAt = async (resolution: ImageResolution) => {
    const res = await getTowerCabImageUpdatedAtAsync(artccId, formik.values.id, resolution);
    if (res.ok) {
      if (resolution === ImageResolution.High) {
        setHighResUpdatedAt(dateToZuluString(new Date(res.data!)));
      } else {
        setLowResUpdatedAt(dateToZuluString(new Date(res.data!)));
      }
    } else if (resolution === ImageResolution.High) {
      setHighResUpdatedAt("Never");
    } else {
      setLowResUpdatedAt("Never");
    }
  };

  useEffect(() => {
    getImageUpdatedAt(ImageResolution.Low);
    getImageUpdatedAt(ImageResolution.High);
  }, []);

  const uploadImage = async (image: FileUpload, resolution: ImageResolution) => {
    if (!(image instanceof File)) {
      return;
    }
    if (resolution === ImageResolution.High) {
      setProcessingHighRes(true);
      highResInputRef.current.clear();
    } else {
      setProcessingLowRes(true);
      lowResInputRef.current.clear();
    }

    const res = await uploadTowerCabImageAsync(artccId, formik.values.id, image, resolution);
    if (res.ok) {
      toast.success("Succesfully uploaded imagery");
      getImageUpdatedAt(ImageResolution.High);
      getImageUpdatedAt(ImageResolution.Low);
    } else {
      toast.error(`Failed to upload imagery: ${res.statusText}`);
    }

    if (resolution === ImageResolution.High) {
      setProcessingHighRes(false);
    } else {
      setProcessingLowRes(false);
    }
  };

  const towerLocationProcessor = (isLatitude: boolean) => (value: string | undefined) => {
    if (value !== undefined && !towerCabConfiguration.towerLocation) {
      formik.setFieldValue("towerCabConfiguration.towerLocation", new GeoPoint());
      formik.setFieldValue("towerCabConfiguration.towerLocation.lon", undefined);
      formik.setFieldValue("towerCabConfiguration.towerLocation.lat", undefined);
    } else if (
      value === undefined &&
      towerCabConfiguration.towerLocation &&
      ((isLatitude && towerCabConfiguration.towerLocation.lon === undefined) ||
        (!isLatitude && towerCabConfiguration.towerLocation.lat === undefined))
    ) {
      formik.setFieldValue("towerCabConfiguration.towerLocation", undefined);
    }
    return value;
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Tower Cab Configuration</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md className="mb-3">
            <Input
              number
              formik={formik}
              label="Tower Location Latitude"
              placeholder="42.3355"
              useUndefinedForEmpty
              name="towerCabConfiguration.towerLocation.lat"
              valueProcessor={towerLocationProcessor(true)}
            />
          </Col>
          <Col md className="mb-3">
            <Input
              number
              useUndefinedForEmpty
              formik={formik}
              label="Tower Location Longitude"
              placeholder="-71.1685"
              name="towerCabConfiguration.towerLocation.lon"
              valueProcessor={towerLocationProcessor(false)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg className="mb-3">
            <Input
              label="Default Rotation"
              name="towerCabConfiguration.defaultRotation"
              formik={formik}
              placeholder="0"
              number
            />
          </Col>
          <Col lg className="mb-3">
            <Input
              label="Default Zoom Range"
              name="towerCabConfiguration.defaultZoomRange"
              formik={formik}
              placeholder="150"
              number
            />
          </Col>
          <Col lg className="mb-3">
            <Input
              label="Aircraft Visibility Ceiling (MSL)"
              name="towerCabConfiguration.aircraftVisibilityCeiling"
              formik={formik}
              placeholder="6000"
              number
            />
          </Col>
        </Row>
        <Row>
          <Col lg className="mb-3">
            <VideoMapInput formik={formik} name="towerCabConfiguration.videoMapId" label="Video Map" />
          </Col>
          <Col lg className="mb-2">
            <S.Label>Satellite Imagery</S.Label>
            <UploadFileButton
              label="Upload High Res"
              onSelect={(f) => uploadImage(f, ImageResolution.High)}
              accept=".jpg"
              processing={processingHighRes}
              fileName={`Last Updated: ${highResUpdatedAt}`}
              ref={highResInputRef}
            />
          </Col>
          <Col lg className="mb-3">
            <S.Label className="d-none d-lg-block">&nbsp;</S.Label>
            <UploadFileButton
              onSelect={(f) => uploadImage(f, ImageResolution.Low)}
              label="Upload Low Res"
              accept=".jpg"
              processing={processingLowRes}
              fileName={`Last Updated: ${lowResUpdatedAt}`}
              ref={lowResInputRef}
            />
          </Col>
          <Col lg className="mb-2">
            <S.Label>Imagery Generator</S.Label>
            <IconButton
              icon={faWindows}
              type="secondary"
              className="btn-block"
              text="Download for Windows"
              onClick={() => window.open(`${DOMAIN}/utilities/TowerCabImageGenerator-win-x64.zip`)}
            />
          </Col>
          <Col lg className="mb-3">
            <S.Label className="d-none d-lg-block">&nbsp;</S.Label>
            <IconButton
              icon={faLinux}
              type="secondary"
              className="btn-block"
              text="Download for Linux"
              onClick={() => window.open(`${DOMAIN}/utilities/TowerCabImageGenerator-linux-x64.tar.gz`)}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer />
    </Card>
  );
}

export default TowerCabConfiguration;
