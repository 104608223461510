import { Artcc, Transceiver } from "@vatsim-vnas/js-libs/models/facilities";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { AddIconButton, DeleteIconButton, Input, SaveButtonBar, Table, TableNoRows } from "src/components/ui";
import { DeleteModal, DeleteModalSpec } from "src/components/ui/modal";
import {
  artccSelector,
  saveArtcc,
  setUnsavedData,
  updateTransceivers,
  useAppDispatch,
  useAppSelector,
} from "src/redux";
import { addToFormikArray, deleteFromFormikArray } from "src/utils";

function Transceivers() {
  const artcc = useAppSelector(artccSelector);
  const dispatch = useAppDispatch();
  const [deleteModalSpec, setDeleteModalSpec] = useState<DeleteModalSpec<Transceiver>>({ show: false });

  const handleSubmit = (values: Artcc, formik: FormikHelpers<Artcc>) => {
    dispatch(updateTransceivers(values.transceivers));
    dispatch(saveArtcc("Successfully saved changes"));
    formik.resetForm({ values });
  };

  const formik = useFormik({
    initialValues: artcc,
    enableReinitialize: true,
    validationSchema: Artcc.transceiversSchema,
    onSubmit: handleSubmit,
  });
  const { transceivers } = formik.values;

  const handleDelete = (transceiver: Transceiver) => {
    const canDeleteSpec = transceiver.canDelete(artcc);
    if (canDeleteSpec.canDelete) {
      deleteFromFormikArray(formik, "transceivers", transceiver);
    } else {
      setDeleteModalSpec({ show: true, item: transceiver, itemName: transceiver.name, canDeleteSpec });
    }
  };

  useEffect(() => {
    dispatch(setUnsavedData(formik.dirty));
  }, [formik.dirty]);

  return (
    <>
      <h1 className="content-header">Transceivers</h1>
      <section className="content">
        <Container fluid>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Card.Header>
                <Card.Title>Transceivers</Card.Title>
                <div className="card-tools d-flex gap-3">
                  <AddIconButton
                    text="Add Transceiver"
                    onClick={() =>
                      addToFormikArray(formik, "transceivers", new Transceiver(), undefined, undefined, true)
                    }
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row className="mt-2">
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Latitude</th>
                          <th>Longitude</th>
                          <th>Height AGL (m)</th>
                          <th>Height MSL (m)</th>
                          <th className="w-0">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transceivers.map((transceiver, i) => (
                          <tr key={transceiver.id}>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`transceivers[${i}].name`}
                                placeholder="Melrose RCAG"
                                allowLowercase
                                onBlur={() =>
                                  formik.setFieldValue(
                                    "transceivers",
                                    [...transceivers].sort((a, b) => a.name.localeCompare(b.name)),
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`transceivers[${i}].location.lat`}
                                placeholder="42.3355"
                                number
                              />
                            </td>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`transceivers[${i}].location.lon`}
                                placeholder="-71.1685"
                                number
                              />
                            </td>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`transceivers[${i}].heightAglMeters`}
                                placeholder="30"
                                number
                              />
                            </td>
                            <td>
                              <Input
                                tableCell
                                formik={formik}
                                name={`transceivers[${i}].heightMslMeters`}
                                placeholder="150"
                                number
                              />
                            </td>
                            <td>
                              <DeleteIconButton onClick={() => handleDelete(transceiver)} />
                            </td>
                          </tr>
                        ))}
                        <TableNoRows rows={transceivers} text="No Transceivers defined" />
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer />
            </Card>
            <SaveButtonBar formik={formik} />
          </Form>
        </Container>
      </section>
      <DeleteModal
        show={deleteModalSpec.show}
        itemName={deleteModalSpec.itemName}
        canDeleteSpec={deleteModalSpec.canDeleteSpec}
        onClose={() => setDeleteModalSpec((p) => ({ ...p, show: false }))}
      />
    </>
  );
}

export default Transceivers;
